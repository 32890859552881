import {IonButton, IonCol, IonRow} from "@ionic/react";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../store";
import {setShowRealResult} from "../../../store/data/actions";
import {DataState} from "../../../store/data/types";
import style from "../InstructionKernel/InstructionKernel.module.scss";

const InstructionOptimalFeatureSelection: React.FC = () => {

    const dispatch = useDispatch();
    const {t} = useTranslation();
    const showRealResult = useSelector<AppState, DataState["showRealResult"]>(state => state.data.showRealResult);

    // reset showRealResult on page loading
    useEffect(() => {
        dispatch(setShowRealResult(false));
    }, [])

    return (
        <div>
            <IonRow>
                <IonCol>
                    <h4 className={style.header}>{t("INSTRUCTIONS.PAGE2.Header")}</h4>
                    <h6 className={style.subheader}>{t("INSTRUCTIONS.PAGE2.SubHeader")}</h6>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <ol>
                        <li className={style.instruction}>{t("INSTRUCTIONS.PAGE5.First")}</li>
                        <li className={style.instruction}>{t("INSTRUCTIONS.PAGE5.Second")}</li>
                        <li className={style.instruction}>{t("INSTRUCTIONS.PAGE5.Third")}</li>
                        <IonButton onClick={() => {
                            dispatch(setShowRealResult(!showRealResult));
                        }}>
                            {!showRealResult && t("INSTRUCTIONS.PAGE5.ShowRealResult")}
                            {showRealResult && t("INSTRUCTIONS.PAGE5.ShowUserResult")}
                        </IonButton>
                    </ol>
                </IonCol>
            </IonRow>
        </div>
    )
}

export default InstructionOptimalFeatureSelection;
