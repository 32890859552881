import undoable from 'redux-undo';
import {
    CLEAR_SVM,
    SET_COST,
    SET_DEGREE,
    SET_GAMMA,
    SET_KERNEL,
    SET_LABELS,
    SET_POINTS,
    SET_PREDICTION,
    STYLE_BREAKPOINT_UPDATE,
    SVC_ADD_POINT,
    SVC_CLEAR_POINTS,
    SVC_LABEL_CHANGED,
    SVC_REDO_POINT,
    SVC_UNDO_POINT,
    SVMActionTypes,
    SvmState
} from './types';

const defaultState: SvmState = {
    points: [
        [
            0.650727294921875,
            0.7756761474609375
        ],
        [
            0.622727294921875,
            0.6276761474609375
        ],
        [
            0.894727294921875,
            0.6696761474609375
        ],
        [
            0.826727294921875,
            0.8776761474609375
        ],
        [
            0.292727294921875,
            0.3796761474609375
        ],
        [
            0.324727294921875,
            0.1996761474609375
        ],
        [
            0.120727294921875,
            0.1776761474609375
        ],
        [
            0.172727294921875,
            0.3596761474609375
        ]
    ],
    labels: [0, 0, 0, 0, 1, 1, 1, 1],
    currentLabel: 0,
    currentBreakpoint: "lg",
    kernel: 0,
    degree: "2",
    gamma: 10,
    cost: 10,
    prediction: [null, null]
};

function SvmReducer(state = defaultState, action: SVMActionTypes) {
    switch (action.type) {
        case SVC_ADD_POINT: {
            const newPoint = [action.payload.point.x, action.payload.point.y];
            return {
                ...state,
                points: state.points.concat([newPoint]),
                labels: state.labels.concat(state.currentLabel)
            };
        }
        case SVC_LABEL_CHANGED: {
            return {
                ...state,
                currentLabel: action.payload
            };
        }
        case SVC_CLEAR_POINTS: {
            return {
                ...state,
                points: [],
                labels: []
            };
        }
        case STYLE_BREAKPOINT_UPDATE: {
            if (action.payload === state.currentBreakpoint) {
                return state;
            } else {
                return {...state, currentBreakpoint: action.payload};
            }
        }

        case SET_KERNEL: {
            return {...state, kernel: action.payload}
        }

        case SET_GAMMA: {
            return {...state, gamma: action.payload}
        }

        case SET_COST: {
            return {...state, cost: action.payload}
        }

        case SET_DEGREE: {
            return {...state, degree: action.payload}
        }

        case CLEAR_SVM: {
            return defaultState;
        }

        case SET_POINTS: {
            return {...state, points: action.payload}
        }

        case SET_LABELS: {
            return {...state, labels: action.payload}
        }

        case SET_PREDICTION: {
            return {...state, prediction: action.payload}
        }

        default:
            return state;
    }
}

export default undoable(SvmReducer, {
    limit: 100,
    undoType: SVC_UNDO_POINT,
    redoType: SVC_REDO_POINT
});
