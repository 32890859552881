import {CLEAR_PRIVACY, PrivacyState, SET_PRIVACY_ACCEPTED, SET_PRIVACY_VERSION, SET_SHOW_PRIVACY} from "./types";

export const setPrivacyVersionAction = (payload: PrivacyState["privacyVersion"]) => {
    return {
        type: SET_PRIVACY_VERSION,
        payload: payload
    }
};

export const setPrivacyAcceptedAction = (payload: PrivacyState["privacyAccepted"]) => {
    return {
        type: SET_PRIVACY_ACCEPTED,
        payload: payload
    }
};

export const setShowPrivacyAction = (payload: PrivacyState["showPrivacy"]) => {
    return {
        type: SET_SHOW_PRIVACY,
        payload: payload
    }
};

export const clearPrivacyAction = () => {
    return {
        type: CLEAR_PRIVACY
    }
};
