import {IonButton, IonCol, IonContent, IonGrid, IonRow} from "@ionic/react";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import style from './EvaluationExtern.module.scss';

const EvaluationExtern: React.FC = () => {

    const {t} = useTranslation();
    const [buttonClicked, setButtonClicked] = useState(false);

    return (
            <IonGrid className={style.evaluation_grid}>
                <IonRow>
                    <IonCol>
                        <h4>{t("EVALUATIONEXTERN.Header")}</h4>
                        <p>{t("EVALUATIONEXTERN.Text")}</p>
                        <IonButton href={"https://olat.vcrp.de/auth/RepositoryEntry/3371500278/CourseNode/105936798706870"} target={"_blank"} onClick={() => {
                            setButtonClicked(true);
                        }}>
                            {t("EVALUATIONEXTERN.StartEvaluation")}
                        </IonButton>
                        {/* {buttonClicked && <p>{t("EVALUATIONEXTERN.Text2")}</p>} */}
                    </IonCol>
                </IonRow>
            </IonGrid>
    )
}

export default EvaluationExtern;
