import {SET_CURRENT_PAGE, SET_LAST_VISIT_DATE} from "../system/types";
import {CHANGE_LANGUAGE, CLEAR_SYSTEM, SET_CAN_GO_NEXT, SET_SHOW_LOADING, SystemActionTypes, SystemState} from "./types";

const INITIAL_STATE: SystemState = {
    currentPage: 0,
    language: "de",
    showLoading: false,
    canGoNext: true,
    lastVisitDate: "2000-01-01T13:00:00+01:00"
};

export const systemReducer = (state: SystemState = INITIAL_STATE, action: SystemActionTypes): SystemState => {
    switch (action.type) {
        case CHANGE_LANGUAGE: {
            return {...state, language: action.payload}
        }
        case SET_SHOW_LOADING: {
            return {...state, showLoading: action.payload}
        }
        case SET_CAN_GO_NEXT: {
            return {...state, canGoNext: action.payload}
        }
        case SET_CURRENT_PAGE: {
            return {...state, currentPage: action.payload}
        }
        case SET_LAST_VISIT_DATE: {
            return {...state, lastVisitDate: action.payload}
        }
        case CLEAR_SYSTEM: {
            return INITIAL_STATE;
        }
        default:
            return state;
    }
};

