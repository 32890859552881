import {IonButton, IonButtons, IonHeader, IonIcon, IonItem, IonPopover, IonTitle, IonToolbar} from "@ionic/react";
import {menu} from "ionicons/icons";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router";
import style from './Header.module.scss';

const Header: React.FC<any> = (props) => {

    const {t} = useTranslation();
    const history = useHistory();
    const [popoverState, setShowPopover] = useState({showPopover: false, event: undefined});
    const pageMap = [1, 2, 3, 4, 5, 6, 7];


    return (
        <IonHeader mode={"md"}>
            <IonToolbar color={"primary"} className={style.header_toolbar}>
                <IonTitle className={style.header_title} onClick={() => {
                    history.replace("/home");
                }}>{t("GENERAL.Title")}</IonTitle>

                <IonButtons slot={"end"} className={style.buttons_wrapper}>
                    <IonButton onClick={(e: any) => {
                        setShowPopover({showPopover: true, event: e})
                    }}><IonIcon icon={menu}/> </IonButton>
                </IonButtons>
                <IonPopover
                    cssClass='my-custom-class'
                    event={popoverState.event}
                    isOpen={popoverState.showPopover}
                    onDidDismiss={() => setShowPopover({showPopover: false, event: undefined})}
                >


                    <IonItem color={"secondary"} button className={style.header_button} routerLink={"/home"} onClick={() => {
                        setShowPopover({showPopover: false, event: undefined})
                    }}>{t("GENERAL.Homepage")}
                    </IonItem>

                    {pageMap.map((value, key) => {
                        return (
                            <IonItem key={key} color={"secondary"} button className={style.header_button} routerLink={"/page/" + value} onClick={() => {
                                setShowPopover({showPopover: false, event: undefined})
                            }}>{t("GENERAL.Step")} {value} - {t("GENERAL.NavigationSubText" + value)}
                            </IonItem>
                        )
                    })}

                    <IonItem color={"secondary"} button className={style.header_button} routerLink={"/page/9"} onClick={() => {
                        setShowPopover({showPopover: false, event: undefined})
                    }}>{t("EVALUATION.Evaluation")}
                    </IonItem>

                    <IonItem color={"secondary"} button className={style.header_button} routerLink={"/impressum"} onClick={() => {
                        setShowPopover({showPopover: false, event: undefined})
                    }}>{t("GENERAL.LegalInformation")}
                    </IonItem>
                    <IonItem color={"secondary"} button className={style.header_button} href={"https://www.umwelt-campus.de/datenschutz"} target={"_blank"} onClick={() => {
                        setShowPopover({showPopover: false, event: undefined})
                    }}>{t("GENERAL.Privacy")}
                    </IonItem>
                </IonPopover>
            </IonToolbar>
        </IonHeader>
    )
}

export default Header;
