import {faBan, faRedo, faUndo} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IonButton, IonCol, IonItem, IonLabel, IonRange, IonRow, IonSelect, IonSelectOption} from "@ionic/react";
import classNames from "classnames";
import React from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../store";
import {changeLabel, clearPoints, redoPoint, setCost, setGamma, setKernel, undoPoint} from "../../../store/SVM/actions";
import {SvmState} from "../../../store/SVM/types";
import style from "./SVMDemonstrator.module.scss";

const Configuration: React.FC<any> = (props) => {

    const dispatch = useDispatch();
    const {t} = useTranslation();
    const svmState = useSelector<AppState, SvmState>(state => state.svm.present);
    const colorstrings = ["apple-juice", "cola", "sparkling-water", "wine"]


    return (
        <IonRow>
            <IonCol size={"12"} sizeMd={"8"} pushMd={"2"}>
                <IonRow>
                    <IonCol>
                        <IonItem>
                            <IonLabel>{t("SVM.Kernel")}</IonLabel>
                            <IonSelect interface={"popover"} value={svmState.kernel} onIonChange={e => {
                                dispatch(setKernel(e.detail.value));
                            }}>
                                <IonSelectOption value={0}>LINEAR</IonSelectOption>
                                <IonSelectOption value={1}>POLYNOMIAL</IonSelectOption>
                                <IonSelectOption value={2}>RBF</IonSelectOption>
                                <IonSelectOption value={3}>SIGMOID</IonSelectOption>
                            </IonSelect>
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem className={style.range_items}>
                            <IonLabel>{t("SVM.Cost")}</IonLabel>
                            <IonRange value={Math.log10(svmState.cost)} min={-3} max={3} step={0.2} onIonChange={e => {
                                dispatch(setCost(Math.pow(10, e.detail.value as number)));

                            }}/>
                            <IonLabel>{svmState.cost && svmState.cost.toExponential(2)}</IonLabel>
                        </IonItem>
                        {svmState.kernel !== 0 && <IonItem className={style.range_items}>
                            <IonLabel>{t("SVM.Gamma")}</IonLabel>
                            <IonRange value={Math.log10(svmState.gamma)} min={-3} max={3} step={0.2} onIonChange={e => {
                                dispatch(setGamma(Math.pow(10, e.detail.value as number)))
                            }}/>
                            <IonLabel>{svmState.gamma && svmState.gamma.toExponential(2)}</IonLabel>
                        </IonItem>}
                    </IonCol>
                </IonRow>
                {props.canAddPoints && <IonRow>
                    <IonCol>
                        <IonRow>
                            <IonCol>
                                <p className={"no-style-paragraph"}>{t("SVM.SelectLabel")}</p>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol className={style.svmChangelabelButtonsCol}>
                                {colorstrings.map((value, key) => {
                                    return (
                                        <IonButton
                                            className={classNames({[style.activeLabel]: svmState.currentLabel === key})}
                                            key={key}
                                            color={value}
                                            style={svmState.currentLabel === key ? {"boxShadow": "2px 2px 16px var(--ion-color-" + value + ")"} : {"boxShadow": "none"}}
                                            onClick={() => {
                                                dispatch(changeLabel(key));
                                            }}>{svmState.currentLabel === key && <p>{t("SVM.LabelButtonSelected")}</p>}</IonButton>
                                    )
                                })}
                            </IonCol>
                        </IonRow>
                    </IonCol>
                </IonRow>}
                {props.canAddPoints && <IonRow>
                    <IonCol className={style.svm_redoButtons}>
                        <FontAwesomeIcon className={style.fontawesomeIcon} icon={faUndo} onClick={() => {
                            dispatch(undoPoint())
                        }}/>
                        <FontAwesomeIcon className={style.fontawesomeIcon} icon={faRedo} onClick={() => {
                            dispatch(redoPoint())
                        }}/>
                        <FontAwesomeIcon className={style.fontawesomeIcon} icon={faBan} onClick={() => {
                            dispatch(clearPoints())
                        }}/>
                    </IonCol>
                </IonRow>}
            </IonCol>
        </IonRow>
    )
}

export default Configuration;
