import * as d3 from "d3";
import React, {useEffect, useRef} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../store";
import {setDrawHyperPlane, setStartKernelAnimation} from "../../../store/data/actions";
import {DataState} from "../../../store/data/types";
import style from './KernelAnimation.module.scss';

const KernelAnimation: React.FC<any> = () => {

    const dispatch = useDispatch();
    const {t} = useTranslation();
    const measurePoints = useSelector<AppState, DataState["measurepoints"]>(state => state.data.measurepoints)
    const startKernelAnimation = useSelector<AppState, DataState["startKernelAnimation"]>(state => state.data.startKernelAnimation);
    const drawHyperPlane = useSelector<AppState, DataState["drawHyperPlane"]>(state => state.data.drawHyperPlane);
    const ref = useRef<SVGSVGElement>(null);

    useEffect(() => {
        dispatch(setStartKernelAnimation(false));
        dispatch(setDrawHyperPlane(false));
    }, [])


    useEffect(() => {
        const svg = d3.select(ref.current);
        const width = 400;
        const height = 400

        // set points for line and point chart
        let line = [[-5, 2], [-4, 2], [-3, 2], [-2, 2], [-1, 2], [0, 2], [1, 2], [2, 2], [3, 2], [4, 2], [5, 2]];


        // make sure svg is empty
        svg.html("");

        const xScale = d3.scaleLinear().domain([-5, 5]).range([0, width]);
        const yScale = d3.scaleLinear().domain([0, 40]).range([height - 10, 0]);

        svg.append("g")
            .attr("transform", "translate(10," + height + ")")
            .call(d3.axisBottom(xScale))
            .attr("class", style.chartAxis)


        // transform data and draw y-axis
        if (startKernelAnimation) {
            const newLine = [...line];
            for (let i = 0; i < newLine.length; i++) {
                newLine[i][1] = newLine[i][0] * newLine[i][0];
            }
            line = newLine;

            svg.append("g")
                .attr("transform", "translate(" + ((width / 2) + 10) + ",10)")
                .call(d3.axisLeft(yScale))
                .attr("class", style.chartAxis);
        }

        svg.append('path')
            .datum(line)
            .attr('fill', 'none')
            .attr('stroke', getComputedStyle(document.documentElement)
                .getPropertyValue('--ion-color-medium'))
            .attr('stroke-width', 1.5)
            .attr("transform", "translate(10,10)")
            .attr(
                'd',
                // @ts-ignore
                d3
                    .line()
                    .x((d) => {
                        return xScale(d[0])
                    })
                    .y((d) => {
                        return yScale(d[1])
                    })
            )


        // seperate points for 2 classes
        const data1 = [];
        const data2 = [];

        for (let i = 0; i < line.length; i++) {
            if (i > 2 && i < 8) {
                data2.push(line[i]);
            } else {
                data1.push(line[i]);
            }
        }

        svg.append('g')
            .selectAll("dot")
            .data(data1)
            .enter()
            .append("circle")
            .attr("cx", function (d) {
                return xScale(d[0]);
            })
            .attr("cy", function (d) {
                return yScale(d[1]);
            })
            .attr("r", 8)
            .attr("transform", "translate(10,10)")
            .style("fill", getComputedStyle(document.documentElement)
                .getPropertyValue('--ion-color-cola'));


        svg.append('g')
            .selectAll("dot")
            .data(data2)
            .enter()
            .append("circle")
            .attr("cx", function (d) {
                return xScale(d[0]);
            })
            .attr("cy", function (d) {
                return yScale(d[1]);
            })
            .attr("r", 8)
            .attr("transform", "translate(10,10)")
            .style("fill", getComputedStyle(document.documentElement)
                .getPropertyValue('--ion-color-apple-juice'));


        svg.append("text")
            .attr("x", 410)
            .attr("y", 420)
            .attr("dy", "12px")
            .text("x")
            .style("font-size", "20px")
            .style("fill", getComputedStyle(document.documentElement)
                .getPropertyValue('--ion-color-light'))

        svg.append("text")
            .attr("x", 0)
            .attr("y", 435)
            .attr("dy", "12px")
            .text(t("KERNEL.NonEatable") as string)
            .style("fill", getComputedStyle(document.documentElement)
                .getPropertyValue('--ion-color-light'))
            .style("font-size", "12px");

        svg.append("text")
            .attr("x", 185)
            .attr("y", 435)
            .attr("dy", "12px")
            .text(t("KERNEL.Eatable") as string)
            .style("fill", getComputedStyle(document.documentElement)
                .getPropertyValue('--ion-color-light'))
            .style("font-size", "12px");

        svg.append("text")
            .attr("x", 350)
            .attr("y", 435)
            .attr("dy", "12px")
            .text(t("KERNEL.NonEatable") as string)
            .style("fill", getComputedStyle(document.documentElement)
                .getPropertyValue('--ion-color-light'))
            .style("font-size", "12px");

        const plane = [[-10, 6.5], [10, 6.5]];

        if (drawHyperPlane && startKernelAnimation) {

            svg.append('path')
                .datum(plane)
                .attr('fill', 'none')
                .attr('stroke', getComputedStyle(document.documentElement)
                    .getPropertyValue('--ion-color-primary'))
                .attr('stroke-width', 1.5)
                .attr("transform", "translate(30,10)")
                .attr(
                    'd',
                    // @ts-ignore
                    d3
                        .line()
                        .x((d) => {
                            return xScale(d[0])
                        })
                        .y((d) => {
                            return yScale(d[1])
                        })
                )
        }


    }, [startKernelAnimation, measurePoints, drawHyperPlane])


    return (
        <div className={style.svg_wrapper}>
            <svg ref={ref} width="420" height="500"/>
        </div>
    )
}

export default KernelAnimation;
