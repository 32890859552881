// canvas resolution
export const CANVAS_RESOLUTION: any = {
    sm: 200,
    md: 300,
    lg: 400,
    xl: 400,
    xxl: 400
}

// scale faktor for canvas
export const CANVAS_SCALE_FACTOR: any = {
    sm: 1,
    md: 1,
    lg: 1,
    xl: 1,
    xxl: 1
}

