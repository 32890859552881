import {
    CLEAR_DATA,
    CLEAR_QUIZ_ANSWERS,
    DataActionTypes,
    DataState,
    SET_CORRECT_QUIZ_ANSWERS,
    SET_DRAW_HYPERPLANE,
    SET_FEATURE_SELECTION,
    SET_MEASUREPOINTS,
    SET_QUIZ_ANSWER,
    SET_SHOW_REAL_RESULT,
    SET_START_KERNEL_ANIMATION
} from "./types";

const INITIAL_STATE: DataState = {
    measurepoints: [],
    startKernelAnimation: false,
    drawHyperPlane: false,
    featureSelection: {
        x: 30,
        y: 400
    },
    showRealResult: false,
    quizAnswers: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    correctQuizAnswers: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
};

export const dataReducer = (state: DataState = INITIAL_STATE, action: DataActionTypes) => {
    switch (action.type) {
        case SET_MEASUREPOINTS:
            return {...state, measurepoints: action.payload}
        case SET_START_KERNEL_ANIMATION:
            return {...state, startKernelAnimation: action.payload}
        case SET_DRAW_HYPERPLANE:
            return {...state, drawHyperPlane: action.payload}
        case SET_FEATURE_SELECTION:
            return {...state, featureSelection: action.payload}
        case SET_SHOW_REAL_RESULT:
            return {...state, showRealResult: action.payload}
        case SET_QUIZ_ANSWER:
            const newState = {...state};
            newState.quizAnswers[action.payload.key] = action.payload.value;
            return newState;
        case SET_CORRECT_QUIZ_ANSWERS:
            return {...state, correctQuizAnswers: action.payload}
        case CLEAR_QUIZ_ANSWERS:
            return {...state, quizAnswers: INITIAL_STATE["quizAnswers"]}
        case CLEAR_DATA:
            return INITIAL_STATE;
        default:
            return {...state};
    }
};
