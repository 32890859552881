import {IonAlert, IonApp, IonLoading, IonRouterOutlet} from '@ionic/react';
import {IonReactRouter} from '@ionic/react-router';
import '@ionic/react/css/core.css';
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/typography.css';
import moment from "moment";
import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {readRemoteFile} from "react-papaparse";
import {useDispatch, useSelector} from "react-redux";
import {Redirect, Route} from 'react-router-dom';
import BasicPage from "./pages/BasicPage/BasicPage";
import Home from './pages/Home/Home';
import Impressum from "./pages/Impressum/Impressum";
import {AppState} from "./store";
import {clearDataStateAction, setMeasurepointsAction} from "./store/data/actions";
import {clearErrorsAction} from "./store/error/actions";
import {ErrorState} from "./store/error/types";
import {clearPrivacyAction} from "./store/privacy/actions";
import {clearSVM, updateStyleBreakpoint} from "./store/SVM/actions";
import {clearSystemStateAction, setLastVisitDate} from "./store/system/actions";
import {SystemState} from "./store/system/types";


/* Theme variables */
import './theme/variables.css';
import useWindowDimensions from "./UseWindowDimensions";

const App: React.FC = () => {

    const dispatch = useDispatch();
    const {t} = useTranslation();
    const error = useSelector<AppState, ErrorState>(state => state.error);
    const showLoading = useSelector<AppState, SystemState["showLoading"]>(state => state.system.showLoading);
    const {width} = useWindowDimensions();
    const lastVisitDate = useSelector<AppState, SystemState["lastVisitDate"]>(state => state.system.lastVisitDate);


    // clear all data if user was more than 24h away
    // This also fixes the problem that occurs when the dev team releases changes to the state. At least every 24h.
    useEffect(() => {

        const date = moment();
        const diff = moment.duration(date.diff(moment(lastVisitDate)));

        if (diff.as("hours") > 24 || !lastVisitDate) {

            dispatch(clearSVM());
            dispatch(clearErrorsAction());
            dispatch(clearSystemStateAction());
            dispatch(clearDataStateAction());
            dispatch(clearPrivacyAction());
            dispatch(setLastVisitDate(date.toISOString()));
        }
    }, [])

    // calculate breakpoint for svm demo
    useEffect(() => {
        setTimeout(() => {
            if (width <= 576) {
                dispatch(updateStyleBreakpoint("sm"));
            } else if (width > 786 && width < 992) {
                dispatch(updateStyleBreakpoint("md"));
            } else if (width > 992 && width <= 1200) {
                dispatch(updateStyleBreakpoint("lg"));
            } else if (width > 1200 && width <= 1500) {
                dispatch(updateStyleBreakpoint("xl"));
            } else if (width > 1500) {
                dispatch(updateStyleBreakpoint("xxl"));
            }
        }, 0);
    }, [width])


    // load the sample data from csv file
    useEffect(() => {
        readRemoteFile("./data/messdaten.csv", {
            complete(res) {
                let data: Array<Array<any>> = res.data as Array<Array<any>>;

                // transform strings to numbers
                for (let i = 1; i < data.length - 1; i++) {
                    for (let j = 1; j < data[i].length; j++) {
                        data[i][j] = parseFloat(data[i][j].replace(",", "."));
                    }
                }

                // remove last empty row
                data.pop();

                dispatch(setMeasurepointsAction(data));
            }
        });
    }, [dispatch])


    const errorAlert = () => {
        return (
            <IonAlert
                isOpen={error.isOpen}
                backdropDismiss={false}
                header={t("ERRORS." + error.title)}
                message={t("ERRORS." + error.message)}
                buttons={[
                    {
                        text: 'Ok',
                        handler: async () => {
                            dispatch(clearErrorsAction());
                        }
                    }
                ]}
            />
        );
    };

    const loadingIndicator = () => {
        return (
            <IonLoading
                isOpen={showLoading}
                message={t("GENERAL.LoadingMessage")}
                translucent
            />
        )
    };


    return (
        <IonApp>
            <IonReactRouter>
                <IonRouterOutlet>
                    <Route exact path={"/home"} component={Home}/>
                    <Route exact path={"/page/:page"} render={(props) => {
                        if (props.match.params.page as unknown as number > 0 && props.match.params.page as unknown as number < 10) {
                            return (<BasicPage {...props}/>);
                        } else {
                            return (<Redirect to={"/home"}/>);
                        }
                    }}/>
                    <Route exact path={"/impressum"} component={Impressum}/>
                    <Route exact path="/">
                        <Redirect to="/home"/>
                    </Route>
                    <Route>
                        <Redirect to="/home"/>
                    </Route>
                </IonRouterOutlet>
            </IonReactRouter>
            {errorAlert()}
            {loadingIndicator()}
        </IonApp>
    );
}

export default App;
