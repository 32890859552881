import {IonCol, IonIcon, IonItem, IonLabel, IonRow} from "@ionic/react";
import classNames from "classnames";
import {checkmark, closeOutline} from "ionicons/icons";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../store";
import {setCorrectQuizAnswer} from "../../store/data/actions";
import {DataState} from "../../store/data/types";

const QuizEvaluationComponent: React.FC = () => {

    const dispatch = useDispatch();
    const {t} = useTranslation();
    const quizAnswers = useSelector<AppState, DataState["quizAnswers"]>(state => state.data.quizAnswers)
    const correctQuizAnswers = useSelector<AppState, DataState["correctQuizAnswers"]>(state => state.data.correctQuizAnswers)
    const questionCountSum = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

    useEffect(() => {
        dispatch(setCorrectQuizAnswer([2, 1, 2, 1, 3, 2, 3, 2, 1, 2]));
    }, [])

    return (
        <IonRow>
            <IonCol>
                {questionCountSum.map((value, key) => {
                    return (
                        <IonRow key={key}>
                            <IonCol size={"12"} className={"quizCol"}>
                                <p dangerouslySetInnerHTML={{__html: t("QUIZ.Question" + (value + 1) + ".Question")}}/>

                                {[1, 2, 3].map((i, key) => {
                                    return (
                                        <IonItem key={key}>
                                            <IonLabel className={
                                                classNames("quizAnswerLabel",
                                                    {"quizAnswerLabelSelected": quizAnswers[value] === i},
                                                    {"quizAnswerLabelCorrect": quizAnswers[value] === i && correctQuizAnswers[value] === quizAnswers[value]},
                                                    {"quizAnswerLabelFalse": quizAnswers[value] === i && correctQuizAnswers[value] !== quizAnswers[value]}
                                                )}>{t("QUIZ.Question" + (value + 1) + ".Answer" + i)}</IonLabel>

                                            {quizAnswers[value] === i && quizAnswers[value] !== correctQuizAnswers[value] && <IonIcon color={"danger"} icon={closeOutline}/>}

                                            {quizAnswers[value] === i && quizAnswers[value] === correctQuizAnswers[value] && <IonIcon color={"success"} icon={checkmark}/>}

                                            {correctQuizAnswers[value] === i && quizAnswers[value] !== correctQuizAnswers[value] && <IonIcon color={"light"} icon={checkmark}/>}
                                        </IonItem>
                                    )
                                })}
                            </IonCol>
                        </IonRow>
                    );
                })}
            </IonCol>
        </IonRow>
    );
}

export default QuizEvaluationComponent;
