import {IonCol, IonContent, IonGrid, IonIcon, IonPage, IonRow} from "@ionic/react";
import classNames from "classnames";
import {glassesOutline, hammerOutline, handRightOutline} from "ionicons/icons";
import React, {useEffect, useRef} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import EvaluationExtern from "../../components/EvaluationExtern/EvaluationExtern";
import BasicExplanationComponent from "../../components/Explanations/BasicExplanationComponent";
import FeatureSelectionGraph from "../../components/GraphicalComponents/FeatureSelectionGraph/FeatureSelectionGraph";
import KernelAnimation from "../../components/GraphicalComponents/KernelAnimation/KernelAnimation";
import OptimalFeatureSelectionGraph from "../../components/GraphicalComponents/OptimalFeatureSelectionGraph/OptimalFeatureSelectionGraph";
import SVMDemonstrator from "../../components/GraphicalComponents/SVM/SVMDemonstrator";
import Header from "../../components/Header/Header";
import InstructionFeatureSelection from "../../components/Instructions/InstructionFeatureSelection/InstructionFeatureSelection";
import InstructionFindHyperplane from "../../components/Instructions/InstructionFindHyperplane/InstructionFindHyperplane";
import InstructionKernel from "../../components/Instructions/InstructionKernel/InstructionKernel";
import InstructionMulticlassSvm from "../../components/Instructions/InstructionMulticlassSvm/InstructionMulticlassSvm";
import InstructionOptimalFeatureSelection from "../../components/Instructions/InstructionOptimalFeatureSelection/InstructionOptimalFeatureSelection";
import InstructionSvm from "../../components/Instructions/InstructionSvm/InstructionSvm";
import NavigationComponent from "../../components/Navigation/Navigation";
import Quiz from "../../components/Quiz/Quiz";
import QuizEvaluationComponent from "../../components/Quiz/QuizEvaluationComponent";
import {AppState} from "../../store";
import {DataState} from "../../store/data/types";
import {setCurrentPageAction} from "../../store/system/actions";
import {SystemState} from "../../store/system/types";
import style from './BasicPage.module.scss';


const BasicPage: React.FC<any> = (props) => {

    const dispatch = useDispatch();
    const {t} = useTranslation();
    const currentPage = useSelector<AppState, SystemState["currentPage"]>(state => state.system.currentPage);
    const featureSelectonByUser = useSelector<AppState, DataState["featureSelection"]>(state => state.data.featureSelection);
    const section1 = useRef<any>(null)
    const section2 = useRef<any>(null)
    const section3 = useRef<any>(null)
    const content = useRef<any>(null);

    useEffect(() => {
        dispatch(setCurrentPageAction(parseInt(props.match.params.page)));
    }, [props])

    // get the explanation for page x
    const getExplanationComponent = () => {
        if (currentPage > 0 && currentPage < 9) {
            return <BasicExplanationComponent/>;
        }
    }

    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
        content.current.scrollToTop();
        if (currentPage < 9) {
            section1.current.scrollTop = 0;
            section2.current.scrollTop = 0;
            if (currentPage < 7) {

                section3.current.scrollTop = 0;
            }
        }
    }, [currentPage])


    // get the graphical component for page x
    const getGraphicalComponent = () => {
        switch (currentPage) {
            case 1:
                return (
                    <div>
                        <h4 className={style.graphical_header}>{t("INSTRUCTIONS.PAGE" + currentPage + ".GraphicalHeader")}</h4>
                        <h6 className={style.graphical_subheader}>{t("INSTRUCTIONS.PAGE" + currentPage + ".GraphicalSubHeader")}</h6>
                        <SVMDemonstrator showHover={true} drawMarkers={true}/>
                    </div>
                );
            case 2:
                return (
                    <div>
                        <h4 className={style.graphical_header}>{t("INSTRUCTIONS.PAGE" + currentPage + ".GraphicalHeader")}</h4>
                        <h6 className={style.graphical_subheader}>{t("INSTRUCTIONS.PAGE" + currentPage + ".GraphicalSubHeader")}</h6>
                        <KernelAnimation/>;
                    </div>);
            case 3:
                return (
                    <div>
                        <h4 className={style.graphical_header}>{t("INSTRUCTIONS.PAGE" + currentPage + ".GraphicalHeader")}</h4>
                        <h6 className={style.graphical_subheader}>{t("INSTRUCTIONS.PAGE" + currentPage + ".GraphicalSubHeader")}</h6>
                        <SVMDemonstrator canAddPoints={true}/>;
                    </div>);
            case 4:
                return (
                    <div>
                        <h4 className={style.graphical_header}>{t("INSTRUCTIONS.PAGE" + currentPage + ".GraphicalHeader")}</h4>
                        <h6 className={style.graphical_subheader}>{t("INSTRUCTIONS.PAGE" + currentPage + ".GraphicalSubHeader")}</h6>
                        <FeatureSelectionGraph labelX={"Temperatur in °C"} labelY={"Sensorwert"}/>;
                    </div>);
            case 5:
                return (
                    <div>
                        <h4 className={style.graphical_header}>{t("INSTRUCTIONS.PAGE" + currentPage + ".GraphicalHeader")}</h4>
                        <h6 className={style.graphical_subheader}>{t("INSTRUCTIONS.PAGE" + currentPage + ".GraphicalSubHeader")}</h6>
                        <OptimalFeatureSelectionGraph
                            showData1={true}
                            showData2={true}
                            showData3={true}
                            showData4={true}
                            tempX={featureSelectonByUser.x}
                            tempY={featureSelectonByUser.y}
                            labelX={featureSelectonByUser.x + " °C"}
                            labelY={featureSelectonByUser.y + " °C"}
                        />
                    </div>)
            case 6:
                return (
                    <div>
                        <h4 className={style.graphical_header}>{t("INSTRUCTIONS.PAGE" + currentPage + ".GraphicalHeader")}</h4>
                        <h6 className={style.graphical_subheader}>{t("INSTRUCTIONS.PAGE" + currentPage + ".GraphicalSubHeader")}</h6>
                        <SVMDemonstrator setNoseData={true}/>;
                    </div>)
            case 7:
                return <Quiz/>
            case 8:
                return <QuizEvaluationComponent/>
        }
    }

    // get the instruction component for page x
    const getInstructionComponent = () => {
        switch (currentPage) {
            case 1:
                return <InstructionSvm/>;
            case 2:
                return <InstructionKernel/>;
            case 3:
                return <InstructionFindHyperplane/>;
            case 4:
                return <InstructionFeatureSelection/>;
            case 5:
                return <InstructionOptimalFeatureSelection/>
            case 6:
                return <InstructionMulticlassSvm/>;
        }
    }


    return (
        <IonPage>
            <Header/>
            <IonContent ref={content}>
                {currentPage < 7 && <IonGrid className={"ion-no-padding"}>
                    <IonRow>
                        <IonCol ref={section1} className={style.section1} size={"12"} sizeMd={"12"} sizeXl={"4"}>
                            <div className={style.section_header_bar}>
                                <IonIcon icon={glassesOutline}/>
                                <span>{t("GENERAL.SectionHeader1")}</span>
                            </div>
                            <div className={style.section1_content}>
                                {getExplanationComponent()}
                            </div>
                        </IonCol>
                        <IonCol ref={section2} className={style.section2} size={"12"} sizeMd={"12"} sizeXl={"4"}>
                            <div className={style.section_header_bar}>
                                <IonIcon icon={handRightOutline}/>
                                <span>{t("GENERAL.SectionHeader2")}</span>
                            </div>
                            <div className={style.section2_content}>
                                {getGraphicalComponent()}
                            </div>
                        </IonCol>
                        <IonCol ref={section3} className={style.section3} size={"12"} sizeMd={"12"} sizeXl={"4"}>
                            <div className={style.section_header_bar}>
                                <IonIcon icon={hammerOutline}/>
                                <span>{t("GENERAL.SectionHeader3")}</span>
                            </div>
                            <div className={style.section3_content}>
                                {getInstructionComponent()}
                            </div>
                        </IonCol>
                    </IonRow>
                </IonGrid>}


                {(currentPage === 7 || currentPage === 8) &&
                <IonGrid className={classNames("ion-no-padding", style.quiz_grid)}>
                    <IonRow className={style.section_quiz_row}>
                        <IonCol ref={section1} className={style.section1} size={"12"} sizeMd={"12"} sizeXl={"3"}>
                            <div className={style.section1_content}>
                                {getExplanationComponent()}
                            </div>
                        </IonCol>
                        <IonCol ref={section2} className={style.section2} size={"12"} sizeMd={"12"} sizeXl={"9"}>
                            <div className={style.section2_content}>
                                {getGraphicalComponent()}
                            </div>
                        </IonCol>
                    </IonRow>
                </IonGrid>}

                {currentPage === 9 && <EvaluationExtern/>}
            </IonContent>
            {currentPage !== 9 && <NavigationComponent/>}
        </IonPage>
    )
}

export default BasicPage;
