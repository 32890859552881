import {IonCol, IonIcon, IonRow} from "@ionic/react";
import * as d3 from "d3";
import {ellipse} from "ionicons/icons";
import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {AppState} from "../../../store";
import {DataState} from "../../../store/data/types";
import style from './FeatureSelectionGraph.module.scss';

const FeatureSelectionGraph: React.FC<any> = (props) => {
    const {t} = useTranslation();
    const measurePoints = useSelector<AppState, DataState["measurepoints"]>(state => state.data.measurepoints)
    const ref = useRef<SVGSVGElement>(null);
    const [resetChart, setResetChart] = useState<boolean>(false);


    useEffect(() => {
        const svg = d3.select(ref.current)

        // make sure svg is empty
        svg.html("");

        const width = 400;
        const height = 400

        const xScale = d3.scaleLinear().domain([30, 400]).range([0, width]);
        const yScale = d3.scaleLinear().domain([-1, 0]).range([height - 10, 0]);


        const xAxis = svg.append("g")
            .attr("transform", "translate(50," + height + ")")
            .attr("class", "xScale")
            .call(d3.axisBottom(xScale))
            .attr("class", style.chartAxis);

        const yAxis = svg.append("g")
            .attr("transform", "translate(50,10)")
            .attr("class", "yScale")
            .call(d3.axisLeft(yScale))
            .attr("class", style.chartAxis);

        svg.append("text")
            .attr("transform",
                "translate(" + (width / 2) + " ," +
                (height + 40) + ")")
            .style("text-anchor", "middle")
            .text(props.labelX)
            .attr("fill", getComputedStyle(document.documentElement)
                .getPropertyValue('--ion-color-light'));

        svg.append("text")
            .attr("transform", "rotate(-90)")
            .attr("y", 0)
            .attr("x", 0 - (height / 2))
            .attr("dy", "1em")
            .style("text-anchor", "middle")
            .text(props.labelY)
            .attr("fill", getComputedStyle(document.documentElement)
                .getPropertyValue('--ion-color-light'));


        for (let i = 1; i < measurePoints.length; i++) {

            let label = measurePoints[i][0];

            let line = [];

            for (let j = 1; j < measurePoints[i].length; j++) {
                line.push([30 + j, measurePoints[i][j]]);
            }


            let colorString = '';
            if (label === "apple juice") {
                colorString = "--ion-color-apple-juice";
            } else if (label === "cola") {
                colorString = "--ion-color-cola";
            } else if (label === "sparkling water") {
                colorString = "--ion-color-sparkling-water";
            } else if (label === "wine") {
                colorString = "--ion-color-wine";
            }

            let color = getComputedStyle(document.documentElement)
                .getPropertyValue(colorString)

            svg.append('path')
                .datum(line)
                .attr('fill', 'none')
                .attr('stroke', color)
                .attr('stroke-width', 1.5)
                .attr("transform", "translate(50,10)")
                .attr("class", "chart_lines")
                .attr(
                    'd',
                    // @ts-ignore
                    d3
                        .line()
                        .x((d) => {
                            return xScale(d[0])
                        })
                        .y((d) => {
                            return yScale(d[1])
                        })
                )
        }
    }, [measurePoints, resetChart])


    return (
        <div>
            <IonRow>
                <IonCol>
                    <div className={style.legend}>
                        <div>
                            <IonIcon icon={ellipse} className={style.legend_dot_data_1}/>
                            <span>{t("LEGEND.AppleJuice")}</span>
                        </div>
                        <div>
                            <IonIcon icon={ellipse} className={style.legend_dot_data_2}/>
                            <span>{t("LEGEND.Cola")}</span>
                        </div>
                        <div>
                            <IonIcon icon={ellipse} className={style.legend_dot_data_3}/>
                            <span>{t("LEGEND.SparklingWater")}</span>
                        </div>
                        <div>
                            <IonIcon icon={ellipse} className={style.legend_dot_data_4}/>
                            <span>{t("LEGEND.Wine")}</span>
                        </div>
                    </div>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol className={style.svg_wrapper}>
                    <svg ref={ref} width="460" height="500"/>
                </IonCol>
            </IonRow>
        </div>
    )
}
export default FeatureSelectionGraph;
