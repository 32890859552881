import {IonCol, IonRow} from "@ionic/react";
import React from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {AppState} from "../../store";
import {DataState} from "../../store/data/types";
import {SystemState} from "../../store/system/types";
import style from './BasicExplanationComponent.module.scss';

const BasicExplanationComponent: React.FC = () => {

    const {t} = useTranslation();
    const currentPage = useSelector<AppState, SystemState["currentPage"]>(state => state.system.currentPage);
    const quizAnswers = useSelector<AppState, DataState["quizAnswers"]>(state => state.data.quizAnswers);
    const correctQuizAnswers = useSelector<AppState, DataState["correctQuizAnswers"]>(state => state.data.correctQuizAnswers);

    // calculate the ammount of correctly given quiz answers
    const getCorrectQuizAnswers = () => {
        let count = 0;
        for (let i = 0; i < quizAnswers.length; i++) {
            if (quizAnswers[i] === correctQuizAnswers[i]) {
                count++;
            }
        }

        let percentage = count / quizAnswers.length;
        const key = percentage === 1 ? 1 : (percentage > 0.7 ? 2 : (percentage > 0.5 ? 3 : percentage === 0.5 ? 4 : 5));

        return (
            <div>
                <p>{t("EXPLANATIONS.PAGE" + currentPage + ".Text" + key)}</p>
                <p className={style.correct_answers}>{t("QUIZ.CorrectAnswers")}</p>
                <p className={style.correct_answers}>{count + "/" + quizAnswers.length}</p>
            </div>
        );
    }

    return (
        <IonRow>
            {currentPage < 7 &&
            <IonCol>
                <div className={style.explanation_wrapper}>
                    <h4 className={style.header}>{t("EXPLANATIONS.PAGE" + currentPage + ".Title1")}</h4>
                    <h6 className={style.subheader}>{t("EXPLANATIONS.PAGE" + currentPage + ".SubTitle1")}</h6>
                    <p className={style.explanation} dangerouslySetInnerHTML={{__html: t("EXPLANATIONS.PAGE" + currentPage + ".Text1")}}/>
                </div>

                <div className={style.explanation_wrapper}>
                    <h4 className={style.header}>{t("EXPLANATIONS.PAGE" + currentPage + ".Title2")}</h4>
                    <h6 className={style.subheader}>{t("EXPLANATIONS.PAGE" + currentPage + ".SubTitle2")}</h6>
                    <p className={style.explanation} dangerouslySetInnerHTML={{__html: t("EXPLANATIONS.PAGE" + currentPage + ".Text2")}}/>
                </div>

                <div className={style.explanation_wrapper}>
                    <h4 className={style.header}>{t("EXPLANATIONS.PAGE" + currentPage + ".Title3")}</h4>
                    <h6 className={style.subheader}>{t("EXPLANATIONS.PAGE" + currentPage + ".SubTitle3")}</h6>
                    <p className={style.explanation} dangerouslySetInnerHTML={{__html: t("EXPLANATIONS.PAGE" + currentPage + ".Text3")}}/>
                </div>
                {currentPage === 3 &&
                <div className={style.explanation_wrapper}>
                    <h4 className={style.header}>{t("EXPLANATIONS.PAGE" + currentPage + ".Title4")}</h4>
                    <h6 className={style.subheader}>{t("EXPLANATIONS.PAGE" + currentPage + ".SubTitle4")}</h6>
                    <p className={style.explanation} dangerouslySetInnerHTML={{__html: t("EXPLANATIONS.PAGE" + currentPage + ".Text4")}}/>
                </div>}
            </IonCol>}

            {currentPage >= 7 && <IonCol>
                <h4 className={style.header}>{t("EXPLANATIONS.PAGE" + currentPage + ".Title1")}</h4>
                <h6 className={style.subheader}>{t("EXPLANATIONS.PAGE" + currentPage + ".SubTitle1")}</h6>
                {currentPage === 7 && <p className={style.explanation} dangerouslySetInnerHTML={{__html: t("EXPLANATIONS.PAGE" + currentPage + ".Text1")}}/>}

                {currentPage === 8 && <div>
                    {getCorrectQuizAnswers()}
                </div>}
            </IonCol>}
        </IonRow>
    )
}

export default BasicExplanationComponent;
