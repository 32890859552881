import {IonCol, IonIcon, IonRow} from "@ionic/react";
import chroma from "chroma-js";
import * as d3 from "d3";
import {ellipse} from "ionicons/icons";
import React, {useEffect, useRef} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {AppState} from "../../../store";
import {DataState} from "../../../store/data/types";
import style from './OptimalFeatureSelectionGraph.module.scss';


const OptimalFeatureSelectionGraph: React.FC<any> = (props) => {

    const {t} = useTranslation();
    const measurePoints = useSelector<AppState, DataState["measurepoints"]>(state => state.data.measurepoints);
    const showRealResult = useSelector<AppState, DataState["showRealResult"]>(state => state.data.showRealResult);
    const ref = useRef<SVGSVGElement>(null);


    useEffect(() => {
        const data1 = [];
        const data2 = [];
        const data3 = [];
        const data4 = [];
        const realData1 = [];
        const realData2 = [];
        const realData3 = [];
        const realData4 = [];
        const labelColors = ["#7DFF00", "#E00008", "#02FCFF", "#8200FF"];
        const greyColors = [];

        for (let i = 1; i < measurePoints.length; i++) {
            const point = [
                measurePoints[i][props.tempX - 29],
                measurePoints[i][props.tempY - 29]
            ]
            const realPoint = [
                measurePoints[i][284],
                measurePoints[i][371]
            ]
            if (i < 21) {
                data1.push(point);
                realData1.push(realPoint)
            } else if (i >= 21 && i < 41) {
                data2.push(point);
                realData2.push(realPoint)
            } else if (i >= 41 && i < 61) {
                data3.push(point);
                realData3.push(realPoint)
            } else if (i >= 61) {
                data4.push(point);
                realData4.push(realPoint)
            }
        }


        for (let color of labelColors) {
            const c = chroma.hex(color);
            const greyScale = (c.rgb()[0] + c.rgb()[1] + c.rgb()[2]) / 3;
            greyColors.push("rgba(" + greyScale + "," + greyScale + "," + greyScale + ", 0.7)")
        }


        const svg = d3.select(ref.current)

        // make sure svg is empty
        svg.html("");

        const width = 400;
        const height = 400

        const xScale = d3.scaleLinear().domain([-1, 0]).range([0, width]);
        const yScale = d3.scaleLinear().domain([-1, 0]).range([height - 10, 0]);


        svg.append("g")
            .attr("transform", "translate(50," + height + ")")
            .call(d3.axisBottom(xScale))
            .attr("class", style.chartAxis);

        svg.append("g")
            .attr("transform", "translate(50,10)")
            .call(d3.axisLeft(yScale))
            .attr("class", style.chartAxis);


        svg.append("text")
            .attr("transform",
                "translate(" + (width / 2) + " ," +
                (height + 40) + ")")
            .style("text-anchor", "middle")
            .text(props.labelX)
            .attr("fill", getComputedStyle(document.documentElement)
                .getPropertyValue('--ion-color-light'));

        svg.append("text")
            .attr("transform", "rotate(-90)")
            .attr("y", 0)
            .attr("x", 0 - (height / 2))
            .attr("dy", "1em")
            .style("text-anchor", "middle")
            .text(props.labelY)
            .attr("fill", getComputedStyle(document.documentElement)
                .getPropertyValue('--ion-color-light'));


        if (props.showData1) {
            svg.append('g')
                .selectAll("dot")
                .data(data1)
                .enter()
                .append("circle")
                .attr("cx", function (d) {
                    return xScale(d[0]);
                })
                .attr("cy", function (d) {
                    return yScale(d[1]);
                })
                .attr("r", showRealResult ? 3 : 4)
                .attr("transform", "translate(50,10)")
                .style("fill", showRealResult ? greyColors[0] : labelColors[0]);
        }

        if (props.showData2) {
            svg.append('g')
                .selectAll("dot")
                .data(data2)
                .enter()
                .append("circle")
                .attr("cx", function (d) {
                    return xScale(d[0]);
                })
                .attr("cy", function (d) {
                    return yScale(d[1]);
                })
                .attr("r", showRealResult ? 3 : 4)
                .attr("transform", "translate(50,10)")
                .style("fill", showRealResult ? greyColors[1] : labelColors[1]);
        }

        if (props.showData3) {
            svg.append('g')
                .selectAll("dot")
                .data(data3)
                .enter()
                .append("circle")
                .attr("cx", function (d) {
                    return xScale(d[0]);
                })
                .attr("cy", function (d) {
                    return yScale(d[1]);
                })
                .attr("r", showRealResult ? 3 : 4)
                .attr("transform", "translate(50,10)")
                .style("fill", showRealResult ? greyColors[2] : labelColors[2]);
        }

        if (props.showData4) {
            svg.append('g')
                .selectAll("dot")
                .data(data4)
                .enter()
                .append("circle")
                .attr("cx", function (d) {
                    return xScale(d[0]);
                })
                .attr("cy", function (d) {
                    return yScale(d[1]);
                })
                .attr("r", showRealResult ? 3 : 4)
                .attr("transform", "translate(50,10)")
                .style("fill", showRealResult ? greyColors[3] : labelColors[3]);
        }

        if (showRealResult) {
            svg.append('g')
                .selectAll("dot")
                .data(realData1)
                .enter()
                .append("circle")
                .attr("cx", function (d) {
                    return xScale(d[0]);
                })
                .attr("cy", function (d) {
                    return yScale(d[1]);
                })
                .attr("r", 4)
                .attr("transform", "translate(50,10)")
                .style("fill", labelColors[0]);

            svg.append('g')
                .selectAll("dot")
                .data(realData2)
                .enter()
                .append("circle")
                .attr("cx", function (d) {
                    return xScale(d[0]);
                })
                .attr("cy", function (d) {
                    return yScale(d[1]);
                })
                .attr("r", 4)
                .attr("transform", "translate(50,10)")
                .style("fill", labelColors[1]);

            svg.append('g')
                .selectAll("dot")
                .data(realData3)
                .enter()
                .append("circle")
                .attr("cx", function (d) {
                    return xScale(d[0]);
                })
                .attr("cy", function (d) {
                    return yScale(d[1]);
                })
                .attr("r", 4)
                .attr("transform", "translate(50,10)")
                .style("fill", labelColors[2]);

            svg.append('g')
                .selectAll("dot")
                .data(realData4)
                .enter()
                .append("circle")
                .attr("cx", function (d) {
                    return xScale(d[0]);
                })
                .attr("cy", function (d) {
                    return yScale(d[1]);
                })
                .attr("r", 4)
                .attr("transform", "translate(50,10)")
                .style("fill", labelColors[3]);
        }


    }, [measurePoints, props, showRealResult])


    return (
        <div>
            <IonRow>
                <IonCol>
                    <div className={style.legend}>
                        <div>
                            <IonIcon icon={ellipse} className={style.legend_dot_data_1}/>
                            <span>{t("LEGEND.AppleJuice")}</span>
                        </div>
                        <div>
                            <IonIcon icon={ellipse} className={style.legend_dot_data_2}/>
                            <span>{t("LEGEND.Cola")}</span>
                        </div>
                        <div>
                            <IonIcon icon={ellipse} className={style.legend_dot_data_3}/>
                            <span>{t("LEGEND.SparklingWater")}</span>
                        </div>
                        <div>
                            <IonIcon icon={ellipse} className={style.legend_dot_data_4}/>
                            <span>{t("LEGEND.Wine")}</span>
                        </div>
                    </div>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol className={style.svg_wrapper}>
                    <svg ref={ref} width="460" height="500"/>
                </IonCol>
            </IonRow>
        </div>
    )
}

export default OptimalFeatureSelectionGraph;
