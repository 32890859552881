import {IonButton, IonCol, IonInput, IonItem, IonLabel, IonRow, IonSelect, IonSelectOption} from "@ionic/react";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../store";
import {setErrorAction} from "../../../store/error/actions";
import {setPrediction} from "../../../store/SVM/actions";
import {SvmState} from "../../../store/SVM/types";
import {SVM} from "../../GraphicalComponents/SVM/SVMDemonstrator";
import style from './InstructionSvm.module.scss';


const InstructionSvm: React.FC = () => {
    // keep in mind: if page 1 is loaded, the svm store gets resetted in the SVMDemonstrator.tsx
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const svmState = useSelector<AppState, SvmState>(state => state.svm.present);
    const [trainedSvm, setTrainedSvm] = useState<any>();
    const [x, setX] = useState(0);
    const [y, setY] = useState(0);
    const [label, setLabel] = useState(-1);

    useEffect(() => {
        const initSvm = () => {
            const svm = new SVM({
                kernel: svmState.kernel,
                type: 0,
                gamma: svmState.gamma,
                cost: svmState.cost,
                degree: svmState.degree,
                quiet: true
            });

            svm.train(svmState.points, svmState.labels);
            setTrainedSvm(svm);
        }
        initSvm();

    }, [svmState])


    const predict = (point: Array<number>) => {
        if (trainedSvm) {
            return trainedSvm.predictOne(point);
        }
    }

    return (
        <div>
            <IonRow>
                <IonCol>
                    <h4 className={style.header}>{t("INSTRUCTIONS.PAGE1.Header")}</h4>
                    <h6 className={style.subheader}>{t("INSTRUCTIONS.PAGE1.SubHeader")}</h6>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <ol>
                        <li className={style.instruction}>{t("INSTRUCTIONS.PAGE1.Hover")}</li>
                        <li className={style.instruction}>{t("INSTRUCTIONS.PAGE1.PredictOne")}</li>
                        <li className={style.instruction}>{t("INSTRUCTIONS.PAGE1.PredictOne2")}</li>
                        <li className={style.instruction}>{t("INSTRUCTIONS.PAGE1.PredictOne3")}</li>
                    </ol>
                </IonCol>
            </IonRow>
            <IonRow className={style.input_row}>
                <IonCol>
                    <IonItem>
                        <IonLabel>{t("INSTRUCTIONS.PAGE1.LabelX")}</IonLabel>
                        <IonInput min={"0"} max={"1"} step={"0.1"} type={"number"} value={x} onIonChange={(e) => {
                            setX(e.detail.value as unknown as number)
                        }}/>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <IonItem>
                        <IonLabel>{t("INSTRUCTIONS.PAGE1.LabelY")}</IonLabel>
                        <IonInput min={"0"} max={"1"} step={"0.1"} type={"number"} value={y} onIonChange={(e) => {
                            setY(e.detail.value as unknown as number)
                        }}/>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <IonItem>
                        <IonLabel>{t("INSTRUCTIONS.PAGE1.Label")}</IonLabel>
                        <IonSelect interface={"popover"} onIonChange={e => {
                            setLabel(e.detail.value);
                        }}>
                            <IonSelectOption value={0}>{t("INSTRUCTIONS.PAGE1.Label0")}</IonSelectOption>
                            <IonSelectOption value={1}>{t("INSTRUCTIONS.PAGE1.Label1")}</IonSelectOption>
                        </IonSelect>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol className={style.button_col}>
                    <IonButton disabled={label === -1} onClick={() => {
                        if (label === predict([x, y])) {
                            dispatch(setErrorAction({title: "Success", message: "SvmPredictSuccessMessage"}))
                        } else {
                            dispatch(setErrorAction({title: "Error", message: "SvmPredictErrorMessage"}))
                        }
                        dispatch(setPrediction([x, y]));
                    }}>{t("INSTRUCTIONS.PAGE1.TestButton")}</IonButton>
                </IonCol>
            </IonRow>
        </div>
    )
}

export default InstructionSvm;
