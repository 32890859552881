import {
    CLEAR_SVM,
    SET_COST,
    SET_DEGREE,
    SET_GAMMA,
    SET_KERNEL,
    SET_LABELS,
    SET_POINTS,
    SET_PREDICTION,
    STYLE_BREAKPOINT_UPDATE,
    SVC_ADD_POINT,
    SVC_CLEAR_POINTS,
    SVC_LABEL_CHANGED,
    SVC_REDO_POINT,
    SVC_UNDO_POINT,
    SvmState
} from './types';

export const updateStyleBreakpoint = (payload: SvmState["currentBreakpoint"]) => {
    return {
        type: STYLE_BREAKPOINT_UPDATE,
        payload
    };
}

export const addPoint = (payload: { point: { x: number, y: number } }) => {
    return {
        type: SVC_ADD_POINT,
        payload
    };
}

export const changeLabel = (payload: SvmState["currentLabel"]) => {
    return {
        type: SVC_LABEL_CHANGED,
        payload
    };
}

export const clearPoints = () => {
    return {
        type: SVC_CLEAR_POINTS
    };
}

export const undoPoint = () => {
    return {
        type: SVC_UNDO_POINT
    };
}

export const redoPoint = () => {
    return {
        type: SVC_REDO_POINT
    };
}

export const setKernel = (payload: SvmState["kernel"]) => {
    return {
        type: SET_KERNEL,
        payload
    }
}

export const setGamma = (payload: SvmState["gamma"]) => {
    return {
        type: SET_GAMMA,
        payload
    }
}

export const setCost = (payload: SvmState["cost"]) => {
    return {
        type: SET_COST,
        payload
    }
}

export const setDegree = (payload: SvmState["degree"]) => {
    return {
        type: SET_DEGREE,
        payload
    }
}


export const setPoints = (payload: SvmState["points"]) => {
    return {
        type: SET_POINTS,
        payload
    }
}

export const clearSVM = () => {
    return {
        type: CLEAR_SVM
    }
}

export const setLabels = (payload: SvmState["labels"]) => {
    return {
        type: SET_LABELS,
        payload
    }
}

export const setPrediction = (payload: SvmState["prediction"]) => {
    return {
        type: SET_PREDICTION,
        payload
    }
}
