export interface DataState {
    measurepoints: Array<any>;
    startKernelAnimation: boolean;
    drawHyperPlane: boolean;
    featureSelection: {
        x: number,
        y: number
    },
    showRealResult: boolean,
    quizAnswers: Array<number>
    correctQuizAnswers: Array<number>
}

export const CLEAR_DATA = "CLEAR_DATA";
export const SET_MEASUREPOINTS = "SET_MEASUREPOINTS";
export const SET_START_KERNEL_ANIMATION = "SET_START_KERNEL_ANIMATION";
export const SET_DRAW_HYPERPLANE = "SET_DRAW_HYPERPLANE";
export const SET_FEATURE_SELECTION = "SET_FEATURE_SELECTION";
export const SET_SHOW_REAL_RESULT = "SET_SHOW_REAL_RESULT";
export const SET_QUIZ_ANSWER = "SET_QUIZ_ANSWER";
export const CLEAR_QUIZ_ANSWERS = "CLEAR_QUIZ_ANSWERS"
export const SET_CORRECT_QUIZ_ANSWERS = "SET_CORRECT_QUIZ_ANSWERS";


export interface SetMeasurePoints {
    type: typeof SET_MEASUREPOINTS;
    payload: DataState["measurepoints"];
}

export interface SetStartKernelAnimation {
    type: typeof SET_START_KERNEL_ANIMATION;
    payload: DataState["startKernelAnimation"];
}

export interface SetDrawHyperPlane {
    type: typeof SET_DRAW_HYPERPLANE;
    payload: DataState["drawHyperPlane"];
}

export interface SetFeatureSelection {
    type: typeof SET_FEATURE_SELECTION;
    payload: DataState["featureSelection"];
}

export interface SetShowRealResult {
    type: typeof SET_SHOW_REAL_RESULT;
    payload: DataState["showRealResult"];
}

export interface ClearData {
    type: typeof CLEAR_DATA
}

export interface SetQuizAnswer {
    type: typeof SET_QUIZ_ANSWER;
    payload: { key: number, value: number };
}

export interface ClearQuizAnswers {
    type: typeof CLEAR_QUIZ_ANSWERS;
}

export interface SetCorrectQuizAnswers {
    type: typeof SET_CORRECT_QUIZ_ANSWERS;
    payload: DataState["correctQuizAnswers"];
}

export type DataActionTypes =
    ClearData
    | SetMeasurePoints
    | SetStartKernelAnimation
    | SetDrawHyperPlane
    | SetFeatureSelection
    | SetShowRealResult
    | SetQuizAnswer
    | SetCorrectQuizAnswers
    | ClearQuizAnswers;


