import {IonButton, IonCol, IonIcon, IonItem, IonLabel, IonRadio, IonRadioGroup, IonRow} from "@ionic/react";
import {chevronBack, chevronForward} from "ionicons/icons";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";
import {AppState} from "../../store";
import {setQuizAnswer} from "../../store/data/actions";
import {DataState} from "../../store/data/types";
import {setCanGoNextAction} from "../../store/system/actions";
import {SystemState} from "../../store/system/types";
import "./Quiz.css";

const Quiz: React.FC = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const {t} = useTranslation();
    const quizAnswers = useSelector<AppState, DataState["quizAnswers"]>(state => state.data.quizAnswers)
    const questionCountSum = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
    const [questionCount, setQuestionCount] = useState(0);
    const currentPage = useSelector<AppState, SystemState["currentPage"]>(state => state.system.currentPage);

    const checkIfCanGoNext = () => {
        let bool = true;
        for (let i = 0; i < quizAnswers.length; i++) {
            if (quizAnswers[i] === 0) {
                bool = false;
            }
        }

        console.log(bool);

        if (bool) {
            dispatch(setCanGoNextAction(true));
        }
    }


    return (
        <IonRow>
            <IonCol>
                {questionCountSum.map((value, key) => {
                    return (
                        <IonRow key={key}>
                            {questionCount === value &&
                            <IonCol size={"12"} className={"quizCol"}>
                                <p dangerouslySetInnerHTML={{__html: t("QUIZ.Question" + (value + 1) + ".Question")}}/>
                                <IonRadioGroup value={quizAnswers[value]} onIonChange={(e) => {
                                    dispatch(setQuizAnswer({key: value, value: e.detail.value}));
                                    checkIfCanGoNext();
                                }}>
                                    <IonItem>
                                        <IonRadio value={1}/>
                                        <IonLabel className={"quizAnswerLabel ion-text-wrap"}>{t("QUIZ.Question" + (value + 1) + ".Answer1")}</IonLabel>
                                    </IonItem>
                                    <IonItem>
                                        <IonRadio value={2}/>
                                        <IonLabel className={"quizAnswerLabel ion-text-wrap"}>{t("QUIZ.Question" + (value + 1) + ".Answer2")}</IonLabel>
                                    </IonItem>
                                    <IonItem>
                                        <IonRadio value={3}/>
                                        <IonLabel className={"quizAnswerLabel ion-text-wrap"}>{t("QUIZ.Question" + (value + 1) + ".Answer3")}</IonLabel>
                                    </IonItem>
                                </IonRadioGroup>
                            </IonCol>}
                        </IonRow>
                    );
                })}
                <IonRow>
                    <IonCol className={"quizNavigationButtonsCol"}>
                        {questionCount > 0 && <IonButton fill={"clear"} onClick={() => {
                            setQuestionCount(questionCount - 1);
                        }}>
                            <IonIcon icon={chevronBack}/>
                            <span>{t("QUIZ.LastQuestion")}</span>
                        </IonButton>}

                        {questionCount < 9 && <IonButton onClick={() => {
                            setQuestionCount(questionCount + 1);
                        }}>
                            <span>{t("QUIZ.NextQuestion")}</span>
                            <IonIcon icon={chevronForward}/>
                        </IonButton>}

                        {questionCount === 9 && <IonButton onClick={() => {
                            history.push("/page/" + (currentPage + 1));
                        }}>
                            <span>{t("QUIZ.Results")}</span>
                        </IonButton>}
                    </IonCol>
                </IonRow>
            </IonCol>
        </IonRow>

    )
}
export default Quiz;
