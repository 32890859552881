export interface SystemState {
    language: string;
    showLoading: boolean;
    canGoNext: boolean;
    currentPage: number
    lastVisitDate: string;
}

export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";
export const SET_SHOW_LOADING = "SET_SHOW_LOADING";
export const CLEAR_SYSTEM = "CLEAR_SYSTEM";
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
export const SET_CAN_GO_NEXT = "SET_CAN_GO_NEXT";
export const SET_LAST_VISIT_DATE = "SET_LAST_VISIT_DATE";


interface ChangeLanguageAction {
    type: typeof CHANGE_LANGUAGE;
    payload: SystemState["language"];
}

interface ToggleShowLoadingAction {
    type: typeof SET_SHOW_LOADING;
    payload: SystemState["showLoading"];
}


export interface SetCanGoNext {
    type: typeof SET_CAN_GO_NEXT;
    payload: SystemState["canGoNext"];
}

export interface ClearSystem {
    type: typeof CLEAR_SYSTEM;
}

export interface SetCurrentPage {
    type: typeof SET_CURRENT_PAGE;
    payload: SystemState["currentPage"];
}

export interface SetLastVisitDate {
    type: typeof SET_LAST_VISIT_DATE;
    payload: SystemState["lastVisitDate"];
}


export type SystemActionTypes = ChangeLanguageAction | ToggleShowLoadingAction | ClearSystem | SetCanGoNext | SetCurrentPage | SetLastVisitDate;
