import {IonCol, IonRow} from "@ionic/react";
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../store";
import {DataState} from "../../../store/data/types";
import {clearSVM, setCost, setKernel, setLabels, setPoints} from "../../../store/SVM/actions";

import Canvas from './Canvas';
import style from './SVMDemonstrator.module.scss';

export const SVM = require('libsvm-js/asm');


// this is the SVMDemonstratorComponent. To add a graphical SVM to the page load this component. Don't load the CanvasComponent directly.
const SVMDemonstrator: React.FC<any> = (props) => {

    const dispatch = useDispatch();
    const measurePoints = useSelector<AppState, DataState["measurepoints"]>(state => state.data.measurepoints);
    const currentPage = useSelector<AppState, number>(state => state.system.currentPage);

    useEffect(() => {
        // reset svmStore on PageLoad
        dispatch(clearSVM());

        //set data for nose
        if (props.setNoseData) {
            const labels = [];
            const points = [];
            for (let i = 1; i < measurePoints.length; i++) {
                const point = [
                    1 + measurePoints[i][284],
                    1 + measurePoints[i][371]
                ]
                points.push(point);
                if (i < 21) {
                    labels.push(0);
                } else if (i >= 21 && i < 41) {
                    labels.push(1);
                } else if (i >= 41 && i < 61) {
                    labels.push(2);
                } else if (i >= 61) {
                    labels.push(3);
                }
            }
            dispatch(setPoints(points));
            dispatch(setLabels(labels))
            dispatch(setKernel(0));
            dispatch(setCost(1000));
        }
    }, [currentPage])


    return (
        <IonRow>
            <IonCol className={style.svm_wrapper}>
                <Canvas style={{imageRendering: 'pixelated', float: 'left'}} showHover={props.showHover} canAddPoints={props.canAddPoints}
                        setNoseData={props.setNoseData}
                        drawMarkers={props.drawMarkers}/>
            </IonCol>
        </IonRow>
    );
}

export default SVMDemonstrator;
