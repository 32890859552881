import {IonButton, IonCol, IonRow, IonSpinner} from "@ionic/react";
import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../store";
import {setErrorAction} from "../../../store/error/actions";
import {setPrediction} from "../../../store/SVM/actions";
import {SvmState} from "../../../store/SVM/types";
import {SVM} from "../../GraphicalComponents/SVM/SVMDemonstrator";
import style from "./InstructionMulticlassSvm.module.scss";

const InstructionMulticlassSvm: React.FC = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const svmState = useSelector<AppState, SvmState>(state => state.svm.present);
    const [trainedSvm, setTrainedSvm] = useState<any>();
    const [isRunning, setIsRunning] = useState(false);
    const videoRef = useRef<any>(null);

    useEffect(() => {
        const initSvm = () => {
            const svm = new SVM({
                kernel: svmState.kernel,
                type: 0,
                gamma: svmState.gamma,
                cost: svmState.cost,
                degree: svmState.degree,
                quiet: true
            });

            svm.train(svmState.points, svmState.labels);
            setTrainedSvm(svm);
        }
        initSvm();

    }, [svmState])


    const predict = () => {
        return new Promise(resolve => {
            setIsRunning(true);
            let x = -1;
            let y = -1;

            let result = 0;
            while (result !== 2) {
                x = Math.random();
                y = Math.random();
                result = trainedSvm.predictOne([x, y]);
            }

            if (trainedSvm) {
                videoRef.current.play();

                setTimeout(() => {
                    setIsRunning(false);
                    dispatch(setPrediction([x, y]));
                    resolve(result);
                }, 3400); // video play time
            }
        });
    }

    return (
        <div>
            <IonRow>
                <IonCol>
                    <h4 className={style.header}>{t("INSTRUCTIONS.PAGE2.Header")}</h4>
                    <h6 className={style.subheader}>{t("INSTRUCTIONS.PAGE2.SubHeader")}</h6>
                </IonCol>
            </IonRow>

            <IonRow>
                <IonCol>
                    <ol>
                        <li className={style.instruction}>{t("INSTRUCTIONS.PAGE6.First")}</li>
                        <li className={style.instruction}>{t("INSTRUCTIONS.PAGE6.Second")}</li>
                        <IonButton disabled={isRunning} onClick={async () => {
                            let prediction = await predict()
                            dispatch(setErrorAction({title: "Success", message: "MSvmPredict" + prediction}))
                        }}>
                            {t("INSTRUCTIONS.PAGE6.PredictRandom")}
                            {isRunning && <IonSpinner name="circles"/>}
                        </IonButton>
                    </ol>
                </IonCol>
            </IonRow>
            <video width={"100%"} ref={videoRef}>
                <source src="./assets/videos/nose-test.mp4" type="video/mp4"/>
            </video>
        </div>
    )
}

export default InstructionMulticlassSvm;
