import {Storage} from "@capacitor/storage";
import {applyMiddleware, combineReducers, createStore} from "redux";
import {composeWithDevTools} from "redux-devtools-extension";
import thunk from "redux-thunk";
import {dataReducer} from "./data/reducers";
import {DataState} from "./data/types";
import {errorReducer} from "./error/reducers";
import {ErrorState} from "./error/types";
import {privacyReducer} from "./privacy/reducers";
import {PrivacyState} from "./privacy/types";
import SvmReducer from "./SVM/reducers";
import {systemReducer} from "./system/reducers";
import {SystemState} from "./system/types";

export interface AppState {
    system: SystemState;
    error: ErrorState;
    privacy: PrivacyState;
    data: DataState;
    svm: any,
}

const rootReducer = combineReducers<AppState>({
    system: systemReducer,
    error: errorReducer,
    privacy: privacyReducer,
    data: dataReducer,
    svm: SvmReducer
});

export default async function configureStore() {
    const middleWare = [thunk];
    const middlewareEnhancer = applyMiddleware(...middleWare);
    const persistendState = await loadState();


    return createStore(
        rootReducer,
        persistendState,
        composeWithDevTools(middlewareEnhancer)
    );
}


export const loadState = async (): Promise<AppState | undefined> => {
    try {
        const serializedState = await Storage.get({key: 'state'});
        if (serializedState.value == null) {
            return undefined;
        }
        return JSON.parse(serializedState.value as string);
    } catch (err) {
        return undefined;
    }
};


export const saveState = (state: AppState) => {
    try {
        const serializedState = JSON.stringify(state);
        Storage.set({key: 'state', value: serializedState});
    } catch {
        console.error("Could not save state");
    }
};
