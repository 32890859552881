import {IonButton, IonCol, IonIcon, IonRow} from "@ionic/react";
import classNames from "classnames";
import {chevronBack, chevronForward, ellipse} from "ionicons/icons";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";
import {AppState} from "../../store";
import {setCanGoNextAction} from "../../store/system/actions";
import {SystemState} from "../../store/system/types";
import style from './Navigation.module.scss';

const NavigationComponent: React.FC = () => {

    const dispatch = useDispatch();
    const {t} = useTranslation();
    const history = useHistory();
    const currentPage = useSelector<AppState, SystemState["currentPage"]>(state => state.system.currentPage);
    const canGoNext = useSelector<AppState, SystemState["canGoNext"]>(state => state.system.canGoNext);


    //disable next button on specific pages
    useEffect(() => {
        if (currentPage === 7) {
            dispatch(setCanGoNextAction(false));
        } else {
            dispatch(setCanGoNextAction(true));
        }
    }, [currentPage])


    const getLastPage = (): string => {
        if (currentPage > 1 && currentPage < 10) {
            return "/page/" + (currentPage - 1);
        } else {
            return "/home";
        }
    }

    const getNextPage = (): string => {
        if (currentPage > 0 && currentPage < 9) {
            return "/page/" + (currentPage + 1);
        } else {
            return "/home";
        }
    }


    return (
        <IonRow className={style.naviagtion_buttons_row}>
            <IonCol class={style.hide_on_md} sizeMd={"9"} size={"12"}>
                <div className={style.progress_wrapper}>
                    <div onClick={() => {
                        history.push("/home");
                    }}>
                        <p>{t("GENERAL.NavigationStart")}</p>
                        <IonIcon icon={ellipse}/>
                    </div>
                    <div className={style.line_div}>
                        <div/>
                    </div>

                    <div className={classNames(
                        style.page_button_wrapper,
                        {[style.page_button_wrapper_active]: currentPage === 1},
                        {[style.page_button_wrapper_done]: currentPage > 1}
                    )} onClick={() => {
                        history.push("/page/1");
                    }}>
                        <h6>{t("GENERAL.Step")} 1</h6>
                        <p>{t("GENERAL.NavigationSubText1")}</p>
                    </div>
                    <div className={style.line_div}>
                        <div/>
                    </div>

                    <div className={classNames(
                        style.page_button_wrapper,
                        {[style.page_button_wrapper_active]: currentPage === 2},
                        {[style.page_button_wrapper_done]: currentPage > 2}
                    )} onClick={() => {
                        history.push("/page/2");
                    }}>
                        <h6>{t("GENERAL.Step")} 2</h6>
                        <p>{t("GENERAL.NavigationSubText2")}</p>
                    </div>
                    <div className={style.line_div}>
                        <div/>
                    </div>

                    <div className={classNames(
                        style.page_button_wrapper,
                        {[style.page_button_wrapper_active]: currentPage === 3},
                        {[style.page_button_wrapper_done]: currentPage > 3}
                    )} onClick={() => {
                        history.push("/page/3");
                    }}>
                        <h6>{t("GENERAL.Step")} 3</h6>
                        <p>{t("GENERAL.NavigationSubText3")}</p>
                    </div>
                    <div className={style.line_div}>
                        <div/>
                    </div>

                    <div className={classNames(
                        style.page_button_wrapper,
                        {[style.page_button_wrapper_active]: currentPage === 4},
                        {[style.page_button_wrapper_done]: currentPage > 4}
                    )} onClick={() => {
                        history.push("/page/4");
                    }}>
                        <h6>{t("GENERAL.Step")} 4</h6>
                        <p>{t("GENERAL.NavigationSubText4")}</p>
                    </div>
                    <div className={style.line_div}>
                        <div/>
                    </div>

                    <div className={classNames(
                        style.page_button_wrapper,
                        {[style.page_button_wrapper_active]: currentPage === 5},
                        {[style.page_button_wrapper_done]: currentPage > 5}
                    )} onClick={() => {
                        history.push("/page/5");
                    }}>
                        <h6>{t("GENERAL.Step")} 5</h6>
                        <p>{t("GENERAL.NavigationSubText5")}</p>
                    </div>
                    <div className={style.line_div}>
                        <div/>
                    </div>

                    <div className={classNames(
                        style.page_button_wrapper,
                        {[style.page_button_wrapper_active]: currentPage === 6},
                        {[style.page_button_wrapper_done]: currentPage > 6}
                    )} onClick={() => {
                        history.push("/page/6");
                    }}>
                        <h6>{t("GENERAL.Step")} 6</h6>
                        <p>{t("GENERAL.NavigationSubText6")}</p>
                    </div>
                    <div className={style.line_div}>
                        <div/>
                    </div>
                    <div className={classNames(
                        style.page_button_wrapper,
                        {[style.page_button_wrapper_active]: currentPage === 7},
                        {[style.page_button_wrapper_done]: currentPage > 7}
                    )} onClick={() => {
                        history.push("/page/7");
                    }}>
                        <h6>{t("GENERAL.Step")} 7</h6>
                        <p>{t("GENERAL.NavigationSubText7")}</p>
                    </div>
                    <div className={style.line_div}>
                        <div/>
                    </div>

                    <div onClick={() => {
                        history.push("/page/9");
                    }}>
                        <p>{t("GENERAL.NavigationEnd")}</p>
                        <IonIcon icon={ellipse}/>
                    </div>
                </div>
            </IonCol>
            <IonCol className={style.naviagtion_buttons_col}>
                <IonButton fill={"clear"} onClick={() => {
                    history.replace(getLastPage());
                }}>
                    <IonIcon icon={chevronBack}/>
                    <span>{t("GENERAL.Back")}</span>
                </IonButton>
                <IonButton disabled={!canGoNext} onClick={() => {
                    history.push(getNextPage());
                }}>
                    <span>{t("GENERAL.Next")}</span>
                    <IonIcon icon={chevronForward}/>
                </IonButton>
            </IonCol>
        </IonRow>
    );
}


export default NavigationComponent;
