import {faBan} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {IonButton, IonCol, IonRow} from "@ionic/react";
import React from "react";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {changeLabel, setCost, setGamma, setKernel, setLabels, setPoints} from "../../../store/SVM/actions";
import Configuration from "../../GraphicalComponents/SVM/Configuration";
import style from "./InstructionFindHyperplane.module.scss";

const InstructionFindHyperplane: React.FC = () => {

    const dispatch = useDispatch();
    const {t} = useTranslation();


    return (
        <div>
            <IonRow>
                <IonCol>
                    <h4 className={style.header}>{t("INSTRUCTIONS.PAGE3.Header")}</h4>
                    <h6 className={style.subheader}>{t("INSTRUCTIONS.PAGE3.SubHeader")}</h6>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <Configuration canAddPoints={true}/>
                </IonCol>
            </IonRow>

            <IonRow>
                <IonCol>
                    <ol>
                        <li className={style.instruction}>{t("INSTRUCTIONS.PAGE3.List1")}</li>
                        <li className={style.instruction}>{t("INSTRUCTIONS.PAGE3.List2")}</li>
                        <IonButton className={style.preset_button} onClick={() => {
                            dispatch(setPoints([
                                [0.19634375, 0.326],
                                [0.30234375, 0.25],
                                [0.17034375, 0.192],
                                [0.26434375, 0.148],
                                [0.60634375, 0.656],
                                [0.82234375, 0.672],
                                [0.75834375, 0.566],
                                [0.70234375, 0.676],
                                [0.65034375, 0.36],
                                [0.61634375, 0.292],
                                [0.37634375, 0.624],
                                [0.39434375, 0.488],
                                [0.18834375, 0.7],
                                [0.55234375, 0.128],
                                [0.09034375, 0.364]
                            ]));
                            dispatch(setLabels([0, 0, 0, 0, 1, 1, 1, 1, 0, 1, 1, 0, 0, 0, 1]))
                            dispatch(setCost(1000))
                            dispatch(setGamma(400))
                            dispatch(setKernel(2));
                            dispatch(changeLabel(0));
                        }}>{t("INSTRUCTIONS.PAGE3.Overfitting")}</IonButton>
                        <li className={style.instruction}>{t("INSTRUCTIONS.PAGE3.List3")}</li>
                        <IonButton className={style.preset_button} onClick={() => {
                            dispatch(setPoints([
                                [0.19634375, 0.326],
                                [0.30234375, 0.25],
                                [0.17034375, 0.192],
                                [0.26434375, 0.148],
                                [0.60634375, 0.656],
                                [0.82234375, 0.672],
                                [0.75834375, 0.566],
                                [0.70234375, 0.676],
                                [0.65034375, 0.36],
                                [0.61634375, 0.292],
                                [0.37634375, 0.624],
                                [0.39434375, 0.488],
                                [0.18834375, 0.7],
                                [0.55234375, 0.128],
                                [0.09034375, 0.364]
                            ]));
                            dispatch(setLabels([0, 0, 0, 0, 1, 1, 1, 1, 0, 1, 1, 0, 0, 0, 1]))
                            dispatch(setCost(0.45))
                            dispatch(setGamma(1))
                            dispatch(setKernel(2));
                            dispatch(changeLabel(0));
                        }}>{t("INSTRUCTIONS.PAGE3.Underfitting")}</IonButton>
                        <li className={style.instruction}>{t("INSTRUCTIONS.PAGE3.List4a")}<FontAwesomeIcon className={"fontawesomeIcon_in_text"} icon={faBan}/>{t("INSTRUCTIONS.PAGE3.List4b")}</li>
                    </ol>
                </IonCol>
            </IonRow>
        </div>
    )
}

export default InstructionFindHyperplane;
