import {SET_CURRENT_PAGE, SET_LAST_VISIT_DATE} from "../system/types";
import {CLEAR_SYSTEM, SET_CAN_GO_NEXT, SET_SHOW_LOADING, SystemState} from "./types";


export const setShowLoadingAction = (payload: SystemState["showLoading"]) => {
    return {
        type: SET_SHOW_LOADING,
        payload
    };
};

export const setCanGoNextAction = (payload: SystemState["canGoNext"]) => {
    return {
        type: SET_CAN_GO_NEXT,
        payload
    }
}

export const setCurrentPageAction = (payload: SystemState["currentPage"]) => {
    return {
        type: SET_CURRENT_PAGE,
        payload
    }
}

export const setLastVisitDate = (payload: SystemState["lastVisitDate"]) => {
    return {
        type: SET_LAST_VISIT_DATE,
        payload
    }
}

export const clearSystemStateAction = () => {
    return {
        type: CLEAR_SYSTEM
    }
}
