import {IonCol, IonContent, IonGrid, IonPage, IonRow} from "@ionic/react";
import React from "react";
import {useTranslation} from "react-i18next";
import Header from "../../components/Header/Header";
import style from './Impressum.module.scss';

const Impressum: React.FC = () => {

    const {t} = useTranslation();

    return (
        <IonPage>
            <Header/>
            <IonContent className={style.impressum_content}>
                <IonGrid>
                    <IonRow>
                        <IonCol>
                            <h2>{t("Imprint.Header")}</h2>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <h3 dangerouslySetInnerHTML={{__html: t("Imprint.SubHeader1")}}/>
                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol>
                            <p dangerouslySetInnerHTML={{__html: t("Imprint.Content1")}}/>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <h3>{t("Imprint.SubHeader2")}</h3>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <p dangerouslySetInnerHTML={{__html: t("Imprint.Content2")}}/>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <h3>{t("Imprint.SubHeader3")}</h3>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <p dangerouslySetInnerHTML={{__html: t("Imprint.Content3")}}/>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <h3>{t("Imprint.SubHeader4")}</h3>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <p dangerouslySetInnerHTML={{__html: t("Imprint.Content4")}}/>
                        </IonCol>
                    </IonRow>

                </IonGrid>
            </IonContent>
        </IonPage>
    )
}

export default Impressum
