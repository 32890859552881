import {
    CLEAR_DATA,
    CLEAR_QUIZ_ANSWERS,
    DataState,
    SET_CORRECT_QUIZ_ANSWERS,
    SET_DRAW_HYPERPLANE,
    SET_FEATURE_SELECTION,
    SET_MEASUREPOINTS,
    SET_QUIZ_ANSWER,
    SET_SHOW_REAL_RESULT,
    SET_START_KERNEL_ANIMATION
} from "./types";


export const setMeasurepointsAction = (payload: DataState["measurepoints"]) => {
    return {
        type: SET_MEASUREPOINTS,
        payload
    }
}

export const setStartKernelAnimation = (payload: DataState["startKernelAnimation"]) => {
    return {
        type: SET_START_KERNEL_ANIMATION,
        payload
    }
}

export const setDrawHyperPlane = (payload: DataState["drawHyperPlane"]) => {
    return {
        type: SET_DRAW_HYPERPLANE,
        payload
    }
}

export const setFeatureSelection = (payload: DataState["featureSelection"]) => {
    return {
        type: SET_FEATURE_SELECTION,
        payload
    }
}

export const setShowRealResult = (payload: DataState["showRealResult"]) => {
    return {
        type: SET_SHOW_REAL_RESULT,
        payload
    }
}

export const setQuizAnswer = (payload: { key: number, value: number }) => {
    return {
        type: SET_QUIZ_ANSWER,
        payload
    }
}

export const setCorrectQuizAnswer = (payload: DataState["correctQuizAnswers"]) => {
    return {
        type: SET_CORRECT_QUIZ_ANSWERS,
        payload
    }
}

export const clearQuizAnswers = () => {
    return {
        type: CLEAR_QUIZ_ANSWERS
    }
}

export const clearDataStateAction = () => {
    return {
        type: CLEAR_DATA
    }
}
