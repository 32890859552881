import {IonCol, IonInput, IonItem, IonLabel, IonRow} from "@ionic/react";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../store";
import {setFeatureSelection} from "../../../store/data/actions";
import {DataState} from "../../../store/data/types";
import {setCanGoNextAction} from "../../../store/system/actions";
import style from "./InstructionFeatureSelection.module.scss";

const InstructionFeatureSelection: React.FC = () => {

    const dispatch = useDispatch();
    const {t} = useTranslation();

    const [featureX, setFeatureX] = useState<number>(30);
    const [featureY, setFeatureY] = useState<number>(400);
    const featureSelectonByUser = useSelector<AppState, DataState["featureSelection"]>(state => state.data.featureSelection);
    const [showErrorMessage, setShowErrorMessage] = useState(false);

    useEffect(() => {
        setFeatureX(featureSelectonByUser.x);
        setFeatureY(featureSelectonByUser.y);
    }, []);


    const setFeatureSelectionByUser = () => {
        if (featureX > 400 || featureX < 30 || featureY > 400 || featureY < 30) {
            dispatch(setCanGoNextAction(false));
            setShowErrorMessage(true);
        } else {
            dispatch(setFeatureSelection({
                x: featureX,
                y: featureY
            }))
            dispatch(setCanGoNextAction(true));
            setShowErrorMessage(false);
        }
    }


    return (
        <div>
            <IonRow>
                <IonCol>
                    <h4 className={style.header}>{t("INSTRUCTIONS.PAGE4.Header")}</h4>
                    <h6 className={style.subheader}>{t("INSTRUCTIONS.PAGE4.SubHeader")}</h6>
                </IonCol>
            </IonRow>
            <ol>
                <IonRow>
                    <IonCol>
                        <li className={style.instruction}>{t("INSTRUCTIONS.PAGE4.First")}</li>
                        <li className={style.instruction}>{t("INSTRUCTIONS.PAGE4.Second")}</li>
                    </IonCol>
                </IonRow>
                <IonRow className={style.input_row}>
                    <IonCol>
                        <IonItem>
                            <IonLabel position={"floating"}>{t("INSTRUCTIONS.PAGE4.Temp1")}</IonLabel>
                            <IonInput value={featureX} type={"number"} min={"30"} max={"400"} step={"1"} onIonChange={(e) => {
                                if (e.detail.value) {
                                    setFeatureX(+e.detail.value)
                                    setFeatureSelectionByUser();
                                }
                            }}/>
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem>
                            <IonLabel position={"floating"}>{t("INSTRUCTIONS.PAGE4.Temp2")}</IonLabel>
                            <IonInput value={featureY} type={"number"} min={"30"} max={"400"} step={"1"} onIonChange={(e) => {
                                if (e.detail.value) {
                                    setFeatureY(+e.detail.value)
                                    setFeatureSelectionByUser();
                                }
                            }}/>
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow className={style.after_input_row}>
                    <IonCol>
                        <li className={style.instruction}>{t("INSTRUCTIONS.PAGE4.Third")}</li>
                    </IonCol>
                </IonRow>
            </ol>
            {showErrorMessage && <IonRow>
                <IonCol>
                    <p className={style.error_message}>{t("ERRORS.RangeErrorMessage")}</p>
                </IonCol>
            </IonRow>}
        </div>
    )
}

export default InstructionFeatureSelection;
