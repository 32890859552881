export interface SvmState {
    points: Array<Array<number>>;
    labels: Array<number>;
    currentLabel: number
    currentBreakpoint: string;
    kernel: number,
    degree: string,
    gamma: number,
    cost: number,
    prediction: Array<number | null>;
}


export const STYLE_BREAKPOINT_UPDATE = 'STYLE_BREAKPOINT_UPDATE';
export const SVC_ADD_POINT = 'SVC_ADD_POINT';
export const SVC_LABEL_CHANGED = 'SVC_LABEL_CHANGED';
export const SVC_CLEAR_POINTS = 'SVC_CLEAR_POINTS';
export const SVC_UNDO_POINT = 'SVC_UNDO_POINT';
export const SVC_REDO_POINT = 'SVC_REDO_POINT';
export const CLEAR_SVM = "CLEAR_SVM";
export const SET_KERNEL = "SET_KERNEL";
export const SET_DEGREE = "SET_DEGREE";
export const SET_GAMMA = "SET_GAMMA";
export const SET_COST = "SET_COST";
export const SET_POINTS = "SET_POINTS";
export const SET_LABELS = "SET_LABELS";
export const SET_PREDICTION = "SET_PREDICTION";


export interface SetStyleBreakpoint {
    type: typeof STYLE_BREAKPOINT_UPDATE,
    payload: SvmState["currentBreakpoint"]
}

export interface SetAddPoint {
    type: typeof SVC_ADD_POINT;
    payload: {
        point: {
            x: number,
            y: number
        }
    };
}

export interface SetChangeLabel {
    type: typeof SVC_LABEL_CHANGED;
    payload: SvmState["currentLabel"]
}


export interface SetClearPoints {
    type: typeof SVC_CLEAR_POINTS;
}

export interface SetUndoPoint {
    type: typeof SVC_UNDO_POINT;
}

export interface SetRedoPoint {
    type: typeof SVC_REDO_POINT;
}

export interface ClearSvm {
    type: typeof CLEAR_SVM;
}

export interface SetKernel {
    type: typeof SET_KERNEL,
    payload: SvmState["kernel"]
}

export interface SetDegree {
    type: typeof SET_DEGREE,
    payload: SvmState["degree"]
}

export interface SetGamma {
    type: typeof SET_GAMMA,
    payload: SvmState["gamma"]
}

export interface SetCost {
    type: typeof SET_COST,
    payload: SvmState["cost"]
}

export interface SetPoints {
    type: typeof SET_POINTS,
    payload: SvmState["points"];
}

export interface SetLabels {
    type: typeof SET_LABELS,
    payload: SvmState["labels"];
}

export interface SetPrediction {
    type: typeof SET_PREDICTION,
    payload: SvmState["prediction"]
}

export type SVMActionTypes =
    SetStyleBreakpoint
    | SetAddPoint
    | SetChangeLabel
    | SetClearPoints
    | SetUndoPoint
    | SetRedoPoint
    | ClearSvm
    | SetKernel
    | SetDegree
    | SetGamma
    | SetCost
    | SetPoints
    | SetLabels
    | SetPrediction;
