import {IonCol, IonRow} from "@ionic/react";
import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../store";
import {addPoint} from "../../../store/SVM/actions";
import {SvmState} from "../../../store/SVM/types";
import style from './Canvas.module.scss';
import {CANVAS_RESOLUTION, CANVAS_SCALE_FACTOR} from "./Constants";
import {SVM} from "./SVMDemonstrator";

const chroma = require('chroma-js');

const Canvas: React.FC<any> = (props) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    let ctx: any;
    const svmCanvas = useRef<HTMLCanvasElement>(null);
    const svmState = useSelector<AppState, SvmState>(state => state.svm.present);
    const [realWidthState, setRealWidthState] = useState<any>(0);
    const [realHeightState, setRealHeightState] = useState<any>(0);
    const labelColors = ["#7DFF00", "#E00008", "#02FCFF", "#8200FF"];
    const [activeHover, setActiveHover] = useState(-1);
    const [labeldPointsState, setLabeldPointsState] = useState<Array<{ label: number, x: number, y: number }>>();
    const [tooltipScale, setTooltipScale] = useState(0);
    const [scaleCount, setScaleCount] = useState<Array<number>>([]);


    // the svm is recalculate on first page load and every time the svmState changes
    useEffect(() => {
        const canvasSize = CANVAS_RESOLUTION[svmState.currentBreakpoint];
        const scale = CANVAS_SCALE_FACTOR[svmState.currentBreakpoint];
        const realWidth = canvasSize * scale;
        const realHeight = canvasSize * scale;
        const labeledPoints: any = [];
        let SVs: any;
        const background: any = [];
        let line: any = [];

        // define the scale labels, for the real nose data we need  -1 to 0
        if (props.setNoseData) {
            setScaleCount([-1, -0.8, -0.6, -0.4, -0.2, 0]);
        } else {
            setScaleCount([0, 0.2, 0.4, 0.6, 0.8, 1]);
        }


        setRealWidthState(realWidth);
        setRealHeightState(realHeight);
        setTooltipScale(canvasSize);


        // draw the scales
        const drawCross = () => {
            ctx.lineWidth = 2;
            ctx.strokeStyle = 'gray';
            ctx.beginPath();
            ctx.lineWidth = 4;
            ctx.moveTo(2, 0);
            ctx.lineTo(2, realHeight);
            ctx.stroke();

            ctx.moveTo(0, 2);
            ctx.lineTo(realWidth, 2);
            ctx.stroke();

            let axesScale = realWidth / 5;

            for (let i = 0; i < 6 + 1; i++) {
                if (i === 0) {
                    continue;
                }
                ctx.strokeStyle = 'gray';
                ctx.lineWidth = 3;
                ctx.beginPath();
                ctx.moveTo(0, i * axesScale);
                ctx.lineTo(10, i * axesScale);
                ctx.stroke();

                ctx.beginPath();
                ctx.moveTo(i * axesScale, 0);
                ctx.lineTo(i * axesScale, 10);
                ctx.stroke();
            }
        }


        const fillBackground = () => {
            ctx.fillStyle = 'lightgray';
            ctx.fillRect(0, 0, realWidth, realHeight);
            ctx.fillStyle = 'black';
        }


        const drawBackground = () => {
            const colorsRgb = labelColors.map((c: any) => {
                return chroma(c.trim()).rgb()
            });


            const data = ctx.createImageData(realWidth, realHeight);
            for (let i = 0; i < canvasSize; i++) {
                for (let j = 0; j < canvasSize; j++) {
                    const px = (j * canvasSize + i);
                    const label = background[px];

                    for (let k = 0; k < scale; k++) {
                        const idx = 4 * scale * (canvasSize * (j * scale + k) + i);
                        for (let l = 0; l < scale; l++) {
                            const idxx = idx + l * 4;
                            data.data[idxx] = colorsRgb[label][0];
                            data.data[idxx + 1] = colorsRgb[label][1];
                            data.data[idxx + 2] = colorsRgb[label][2];
                            data.data[idxx + 3] = 255;
                        }
                    }
                }
            }
            ctx.putImageData(data, 0, 0);
        }


        const drawLine = () => {
            ctx.beginPath();
            ctx.moveTo(0, line[0] * canvasSize * scale);
            for (let i = 1; i < canvasSize; i++) {
                ctx.lineTo(i * scale, line[i] * canvasSize * scale);
                ctx.moveTo(i * scale, line[i] * canvasSize * scale);
            }
            ctx.stroke();
        }


        const drawPoints = () => {

            const colorsBrighter = labelColors.map((c: any) => chroma(c).brighten().hex());
            const SVIdx: any = {};
            SVs.forEach((idx: any) => (SVIdx[idx] = 1));
            const radius = scale * Math.min(canvasSize, canvasSize) / 80;
            const markers: any = [];
            for (let i = 0; i < labeledPoints.length; i++) {
                const point = labeledPoints[i];
                let lineFactor = 4;
                if (SVIdx[i]) lineFactor = 2;
                if (props.drawMarkers) {
                    markers[i] = new Image();

                    if (point.label === 0) {
                        markers[i].src = './assets/icon/apple-alt-solid.svg';
                    } else {
                        markers[i].src = './assets/icon/carrot-solid.svg';
                    }

                    addMarker(markers[i], point, i);
                } else {
                    ctx.beginPath();
                    ctx.arc(point.x * scale, point.y * scale, radius, 0, 2 * Math.PI, false);
                    ctx.fillStyle = colorsBrighter[point.label];
                    ctx.fill();
                    ctx.lineWidth = radius / lineFactor;
                    ctx.strokeStyle = '#003300';
                    ctx.stroke();

                }

            }
        }

        const addMarker = (marker: any, point: any, i: any) => {
            marker.onload = () => {
                ctx.drawImage(marker, (point.x * scale) - 10, (point.y * scale) - 10, 20, 20);
            };

        }


        const drawPrediction = () => {
            // draw users prediction
            if (svmState.prediction[0] != null && svmState.prediction[1] != null) {
                const radius = scale * Math.min(canvasSize, canvasSize) / 80;
                const point = svmState.prediction;
                let lineFactor = 2;
                ctx.moveTo(0, 0)
                ctx.beginPath();
                // @ts-ignore
                ctx.arc(point[0] * canvasSize * scale, point[1] * canvasSize * scale, radius, 0, 2 * Math.PI, false);
                ctx.fillStyle = "#FE1C80";
                ctx.fill();
                ctx.lineWidth = radius / lineFactor;
                ctx.strokeStyle = '#fff';
                ctx.stroke();
            }
        }


        const draw = async () => {
            if (background.length !== canvasSize * canvasSize) {
                fillBackground();
            } else {
                drawBackground();
            }
            if (line.length === canvasSize) {
                drawLine();
            }
            if (labeledPoints.length > 0) {
                await drawPoints();
            }
            drawCross();
            drawPrediction();
        }


        // setup points for canvas
        for (let i = 0; i < svmState.points.length; i++) {
            labeledPoints.push({
                label: svmState.labels[i],
                x: svmState.points[i][0] * canvasSize,
                y: svmState.points[i][1] * canvasSize
            });
        }

        setLabeldPointsState(labeledPoints);


        if (labeledPoints.length > 0) {
            // create the svm the config from svmState
            const svm = new SVM({
                kernel: svmState.kernel,
                type: 0,
                gamma: svmState.gamma,
                cost: svmState.cost,
                degree: svmState.degree,
                quiet: true
            });

            // train the svm with the points and labels from state
            // if the nose data should be shown, the SVMDemonstratorComponent sets up the nose data and pushes them to the state
            svm.train(svmState.points, svmState.labels);
            SVs = svm.getSVIndices();


            for (let i = 0; i < canvasSize; i++) {
                for (let j = 0; j < canvasSize; j++) {
                    let val = svm.predictOne([j / canvasSize, i / canvasSize]);
                    if (svm.type === "2") {
                        if (val < 0) {
                            val = 1;
                        } else {
                            val = 0;
                        }
                    }
                    background.push(val);
                }
            }

        }

        //@ts-ignore
        ctx = svmCanvas.current.getContext('2d');
        ctx.imageSmoothingEnabled = false;
        setTimeout(() => {
            draw();
        }, 100)


        // add hover effect
        if (svmCanvas.current && props.showHover) {
            svmCanvas.current.onmouseleave = function () {
                draw();
            }
            svmCanvas.current.onmousemove = function (e) {
                if (svmCanvas.current) {
                    var rect = svmCanvas.current.getBoundingClientRect(),
                        x = e.clientX - rect.left,
                        y = realHeight - (e.clientY - rect.top)


                    ctx.clearRect(0, 0, svmCanvas.current.width, svmCanvas.current.height);
                    drawBackground();
                    drawCross();

                    const colorsBrighter = labelColors.map((c: any) => chroma(c).brighten().hex());
                    const SVIdx: any = {};
                    SVs.forEach((idx: any) => (SVIdx[idx] = 1));
                    const radius = scale * Math.min(canvasSize, canvasSize) / 80;
                    for (let i = 0; i < labeledPoints.length; i++) {
                        const point = labeledPoints[i];
                        let lineFactor = 4;
                        if (SVIdx[i]) lineFactor = 2;
                        ctx.beginPath();
                        ctx.arc(point.x * scale, point.y * scale, radius, 0, 2 * Math.PI, false);
                        ctx.fillStyle = colorsBrighter[point.label];
                        ctx.fill();
                        ctx.lineWidth = radius / lineFactor;
                        ctx.strokeStyle = '#003300';
                        ctx.stroke();
                        // check if we hover it, fill red, if not fill it blue
                        ctx.fillStyle = ctx.isPointInPath(x, y) ? "black" : colorsBrighter[point.label];
                        if (ctx.isPointInPath(x, y)) {
                            setActiveHover(i);
                        }
                        ctx.fill();
                    }
                    drawPrediction();
                }
            }
        }
    }, [svmState])


    // clickhandler
    const onCanvasClick = (event: any) => {
        if (props.canAddPoints) {
            const targetRect = event.target.getBoundingClientRect();
            const normalized = {
                x: (event.clientX - targetRect.left) / realWidthState,
                y: (realHeightState - (event.clientY - targetRect.top)) / realHeightState
            };

            dispatch(addPoint({
                point: normalized
            }));
        }
    }


    return (
        <div>

            <IonRow>
                <IonCol size={"12"}>
                    <div className={style.xScaleSvm} style={{"width": realWidthState}}>
                        {scaleCount.map((val, key) => {
                            return (
                                <span key={key}>{val}</span>
                            )
                        })}
                    </div>
                    <div className={style.yScaleSvm} style={{"height": realHeightState}}>
                        {scaleCount.map((val, key) => {
                            return (
                                <span key={key}>{val}</span>
                            )
                        })}
                    </div>
                    <div className={style.xLabelSvm} style={{left: (realWidthState + 15) + "px", bottom: "-5px"}}>
                        <span>x</span>
                    </div>
                    <div className={style.yLabelSvm} style={{left: "-35px", top: "-15px"}}>
                        <span>y</span>
                    </div>
                    <canvas
                        onClick={onCanvasClick.bind(this)}
                        width={realWidthState}
                        height={realHeightState}
                        ref={svmCanvas}
                        style={props.style}
                        className={style.svm_canvas}
                    />
                </IonCol>
            </IonRow>
            <IonRow>
                {props.showHover && <IonCol className={style.hover_col} size={"12"}>
                    {labeldPointsState && activeHover > -1 && <div>
                        {(activeHover === 4 || activeHover === 1) && <h5>{t("SVM.IsSupportVector")}</h5>}
                        <p>X:{labeldPointsState[activeHover].x / tooltipScale}</p>
                        <p>Y:{labeldPointsState[activeHover].y / tooltipScale}</p>
                        <p>{t("SVM.Label")} {t("SVM.Label" + labeldPointsState[activeHover].label)}</p>
                    </div>}
                </IonCol>}
            </IonRow>
        </div>
    );


}


export default Canvas;
