import {IonButton, IonCol, IonRow} from "@ionic/react";
import React from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../store";
import {setDrawHyperPlane, setStartKernelAnimation} from "../../../store/data/actions";
import {DataState} from "../../../store/data/types";
import style from './InstructionKernel.module.scss';

const InstructionKernel: React.FC = () => {

    const dispatch = useDispatch();
    const {t} = useTranslation();
    const startKernelAnimation = useSelector<AppState, DataState["startKernelAnimation"]>(state => state.data.startKernelAnimation);
    const drawHyperPlane = useSelector<AppState, DataState["drawHyperPlane"]>(state => state.data.drawHyperPlane);

    return (
        <div>
            <IonRow>
                <IonCol>
                    <h4 className={style.header}>{t("INSTRUCTIONS.PAGE2.Header")}</h4>
                    <h6 className={style.subheader}>{t("INSTRUCTIONS.PAGE2.SubHeader")}</h6>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <ol>
                        <li className={style.instruction}>{t("INSTRUCTIONS.PAGE2.Instruction1")}</li>
                        <IonButton className={style.kernel_buttons} onClick={() => {
                            dispatch(setStartKernelAnimation(!startKernelAnimation));
                        }}>
                            {t("INSTRUCTIONS.PAGE2.StartKernel")}
                        </IonButton>
                        {startKernelAnimation && <div>
                            <li className={style.instruction}>{t("INSTRUCTIONS.PAGE2.Instruction2")}</li>
                            <IonButton className={style.kernel_buttons} onClick={() => {
                                dispatch(setDrawHyperPlane(!drawHyperPlane));
                            }
                            }>{t("INSTRUCTIONS.PAGE2.DrawHyperPlane")}</IonButton>
                        </div>}
                    </ol>
                </IonCol>
            </IonRow>
        </div>
    )
}

export default InstructionKernel;
