import {Dispatch} from "react";
import {setShowLoadingAction} from "../system/actions";
import {CLEAR_ERRORS, SET_ERRORS} from "./types";


// message and title are the translation Keys inside the ERRORS field in translation
export const setErrorAction = (payload: { message: string, title: string }) => (dispatch: Dispatch<any>) => {
    dispatch(setShowLoadingAction(false));
    dispatch({
        type: SET_ERRORS,
        payload: payload
    });
};

export const clearErrorsAction = () => {
    return {
        type: CLEAR_ERRORS,
        payload: {}
    };
};
