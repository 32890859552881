// @ts-ignore
import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import {Provider} from "react-redux";
import App from './App';
import './i18n';
import configureStore, {saveState} from "./store";


configureStore().then(persistendStore => {
    const store = persistendStore;

    store.subscribe(() => {
        saveState(store.getState());
    });

    ReactDOM.render(
        <Provider store={store}>
            <Suspense fallback="...loading">
                <App/>
            </Suspense>
        </Provider>
        , document.getElementById('root'));

});
